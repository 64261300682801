import { useContext, useState } from 'react';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";
import { DataGrid } from '@mui/x-data-grid';
import { makeStyles } from '@material-ui/styles';

import 'date-fns';
import { classNameForSKU } from '../sku';
import Report from '../components/Report';
import { SKUInfo } from '../components/SKUInfo';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .super-app-theme--header': {
      backgroundColor: '#f0f0f0',
      fontWeight: 'bold',
      fontSize: '10px'
    },
    '& .super-app-theme--paid': {
      backgroundColor: 'rgb(206, 230, 247)',
      fontWeight: 'bold',
      fontSize: '10px'
    },
    '& .super-app-theme--unpaid': {
      backgroundColor: 'rgb(247, 239, 216)',
      fontWeight: 'bold',
      fontSize: '10px'
    },
    '& .super-app-theme--column-group': {
      fontWeight: 'bold',
      fontSize: '12px',
      textTransform: 'uppercase',
    }
  }, backdrop: {
    zIndex: theme.zIndex.drawer - 2,
    color: '#fff',
  },
}));

const OrderReport = () => {
  let history = useHistory();
  const classes = useStyles();

  const skuInfo = useContext(SKUInfo);

  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [columnGroups, setColumnGroups] = useState([]);

  const [reportDownLoad, setReportDownLoad] = useState('');
  const [showBackdrop, setShowBackdrop] = useState(false);

  const handleReportRun = (dates) => {
    setShowBackdrop(true);

    axios.post(
      `/reports/run/order_report?key=${localStorage.getItem("apikey")}`,
      {
        "date_range": dates.start.toLocaleDateString('en-CA') + "," + dates.end.toLocaleDateString('en-CA')
      },
      {
        headers: { 'content-type': 'application/json' }
      }).then(function (response) {
        let tmpCol = [];
        let tmpGroups = [
          {
            groupId: 'Orders Taken With Full Payment',
            headerClassName: 'super-app-theme--paid super-app-theme--column-group',
            headerAlign: 'center',
            children: []
          },
          {
            groupId: 'Orders Taken Without Full Payment',
            headerClassName: 'super-app-theme--unpaid super-app-theme--column-group',
            headerAlign: 'center',
            children: []
          }
        ];

        for (var colKey in response.data.headers) {
          const sku = colKey.toUpperCase().replace('_PAID', '').replace('_UNPAID', '');
          
          const headerClass = `sku-header-${sku}`;
          const cellClass = `sku-${sku}`;
          
          let col = {
            field: colKey,
            headerName: response.data.headers[colKey].replace(' (paid)', '').replace(' (unpaid)', ''),
            headerClassName: ((valHead) => {
              if (valHead.field.indexOf('un') !== -1) {
                return `super-app-theme--unpaid ${headerClass}`
              } else if (valHead.field.indexOf('paid') !== -1) {
                return `super-app-theme--paid ${headerClass}`
              } else {
                return `super-app-theme--header`
              }
            }),
            cellClassName: cellClass,
            sortable: (colKey !== 'date' ? false : true),
            headerAlign: 'center',
            align: 'center'
          };

          if (/^((x[a-z]{2})|total)_(un)?paid$/.test(colKey)) {
            const isPaid = !colKey.endsWith("unpaid");
            tmpGroups[isPaid ? 0 : 1].children.push(
              { field: colKey }
            );
          } else {
            col["width"] = 200;
          }

          tmpCol.push(col)
        }

        let tmpRow = response.data.rows;
        for (var rowKey in tmpRow) {
          tmpRow[rowKey].id = parseInt(rowKey)
        }

        setRows(tmpRow);
        setColumns(tmpCol);
        setColumnGroups(tmpGroups);
        setReportDownLoad(response.data.links.csv);
        setShowBackdrop(false);
      }).catch(function (error) {
        console.log(error);
      });
  }

  return (
    <>
      <Helmet>
        <title>Order Report | Xmas Cookies</title>
      </Helmet>
      <Report
        onRun={handleReportRun}
        downloadURL={reportDownLoad}>
        <div style={{ overflowX: 'scroll', height: 800 }} className={classes.root}>
          <DataGrid
            sx={skuInfo.classes}
            experimentalFeatures={{ columnGrouping: true }}
            rows={rows}
            columns={columns}
            pageSize={100}
            disableSelectionOnClick={true}
            columnGroupingModel={columnGroups}
            onRowClick={(GridRowParams, event) => {
              console.log(GridRowParams);
              history.push("/tasks/revieworders/" + GridRowParams.id)
            }}
          />
        </div>
      </Report>
      <Backdrop className={classes.backdrop} open={showBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default OrderReport;
