import {
  Paper,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  Stack,
  Grid
} from '@mui/material';

import Section from '../Section';
import CurrencyInput from '../../CurrencyInput';
import { useState, useEffect } from 'react';

const Takings = (props) => {
  const [actualTakingsCash, setActualTakingsCash] = useState(0);
  const [actualTakingsEftpos, setActualTakingsEftpos] = useState(0);
  const [actualTotal, setActualTotal] = useState(0);

  useEffect(() => {
    setActualTakingsCash(props.dailyFigures.received_cash / 100);
    setActualTakingsEftpos(props.dailyFigures.received_eftpos / 100);
  }, [props.dailyFigures]);

  useEffect(() => {
    setActualTotal((actualTakingsCash + actualTakingsEftpos));
  }, [actualTakingsCash, actualTakingsEftpos]);

  const [showDialog, setShowDialog] = useState(false);
  const [note, setNote] = useState("");

  const [dialogError, setDialogError] = useState(false);

  const handleConfirm = () => {
    const expectedCash = (props.expectedFigures.takings_cash / 100).toFixed(2);
    const expectedEftpos = (props.expectedFigures.takings_eftpos / 100).toFixed(2);
    const actualCash = actualTakingsCash.toFixed(2);
    const actualEftpos = actualTakingsEftpos.toFixed(2);
    const areTakingsOk = actualCash === expectedCash && actualEftpos === expectedEftpos;

    console.log(`Expected: ${expectedCash} / ${expectedEftpos}`);
    console.log(`Actual: ${actualCash} / ${actualEftpos}`);
    
    if (!note && !areTakingsOk) {
      setShowDialog(true);
      return;
    }

    if (props.onConfirm) {
      const takings = {
        'received_cash': Math.floor(actualTakingsCash * 100),
        'received_eftpos': Math.floor(actualTakingsEftpos * 100),
        'takings_note': areTakingsOk ? "" : note
      };

      props.onConfirm(takings);
    }
  };

  const handleDialogClose = () => {
    setDialogError(false);
    setShowDialog(false);
    setNote("");
  }

  const handleDialogSave = () => {
    if (!note) {
      setDialogError(true);
      return;
    }

    setDialogError(false);
    setShowDialog(false);
    handleConfirm();
  };

  return (
    <>
      <Section
        title="My Takings"
        {...props}
        onConfirm={handleConfirm}
      >
        <Grid container spacing={2}>
          <Grid item xs={6} md={6} sm={12}>
            <Paper variant="outlined" sx={{ padding: '24px' }}>
              <Typography style={{ fontWeight: 'bold', marginBottom: '12px' }}>
                EXPECTED (All)
              </Typography>
              <Stack direction="row" spacing={2}>
                <CurrencyInput
                  label="Cash"
                  value={(props.expectedFigures.takings_cash / 100 || 0).toFixed(2)}
                  disabled={true}
                />
                <CurrencyInput
                  label="Eftpos"
                  value={(props.expectedFigures.takings_eftpos / 100 || 0).toFixed(2)}
                  disabled={true}
                />
                <CurrencyInput
                  label="Total"
                  value={((parseInt(props.expectedFigures.takings_cash) + parseInt(props.expectedFigures.takings_eftpos) || 0) / 100).toFixed(2)}
                  disabled={true}
                />
              </Stack>
            </Paper>
          </Grid>
          <Grid item xs={6} md={6} sm={12}>
            <Paper variant="outlined" sx={{ padding: '24px' }}>
              <Typography style={{ fontWeight: 'bold', marginBottom: '12px' }}>
                ACTUAL (All)
              </Typography>
              <Stack direction="row" spacing={2}>
                <CurrencyInput
                  label="Cash"
                  value={actualTakingsCash.toFixed(2)}
                  disabled={props.isComplete}
                  onUpdate={(value) => {
                    setActualTakingsCash(value);
                  }}
                />
                <CurrencyInput
                  label="Eftpos"
                  value={actualTakingsEftpos.toFixed(2)}
                  disabled={props.isComplete}
                  onUpdate={(value) => {
                    setActualTakingsEftpos(value);
                  }}
                />
                <CurrencyInput
                  label="Total"
                  disabled={true}
                  value={actualTotal.toFixed(2)}
                />
              </Stack>
            </Paper>
          </Grid>
        </Grid>
      </Section>
      <Dialog open={showDialog}>
        <DialogTitle>
          Expected takings do not match actual takings
        </DialogTitle>

        <DialogContent>
          <Typography>
            Please amend your figures or write a comment below to explain why there is a difference.
          </Typography>

          <TextField
            multiline
            fullWidth
            variant="outlined"
            minRows={4}
            value={note}
            error={dialogError}
            onChange={
              (event) => {
                setNote(event.target.value);
              }
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDialogSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Takings;
