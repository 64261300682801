import React, { useEffect } from 'react';
import {
  Box,
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup, Backdrop, CircularProgress
} from '@material-ui/core';

import { Helmet } from "react-helmet";
import { makeStyles } from '@material-ui/styles';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const formStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    verticalAlign: 'middle'
  }
}));

const PasswordReducerAction = {
  CurrentPassword: Symbol('current-password'),
  NewPassword: Symbol('new-password'),
  NewPasswordConfirm: Symbol('confirm-new-password')
}

const passwordReducer = (state, action) => {
  switch (action.type) {
  case PasswordReducerAction.CurrentPassword:
    return {
      ...state,
      current_password: action.value
    };

  case PasswordReducerAction.NewPassword:
    return {
      ...state,
      new_password: action.value
    };

  case PasswordReducerAction.NewPasswordConfirm:
    return {
      ...state,
      new_password_confirm: action.value
    };
  }
}

const SettingsLabel = ({ children }) => {
  const formClasses = formStyles();

  return (
    <FormControl className={formClasses.root}>
      <Typography style={{ color: '#666666' }}
                  color="textPrimary"
                  variant="h6">
        {children}
      </Typography>
    </FormControl>
  )
}

const Profile = () => {
  const formClasses = formStyles();
  const classes = useStyles();
  const [userInfo, setUserInfo] = React.useState({});
  const [userPwd, setUserPwd] = React.useState({ "password": '', "current_password": '' });
  const [userConPwd, setUserConPwd] = React.useState('');
  const [backDropSts, setBackDropSts] = React.useState(true);
  const [userDbdMode, setUserDbdMode] = React.useState('');
  const [userGoal, setUserGoal] = React.useState('');

  const [password, dispatchPassword] = React.useState({ 'password': '', 'current_password': '', 'confirm_password': ''})

  const handleGoalUpdate = () => {
    setBackDropSts(true);
    axios.put('/users?key=' + localStorage.getItem("apikey"), JSON.stringify({ "target_seller_defined": userGoal, "dashboard_mode": userDbdMode }), {
      headers: { 'Content-Type': 'application/json' }
    })
      .then((response) => {
        alert('Success')
        getUser();
      })
      .catch((error) => {
        setBackDropSts(false);
      })
  }

  const handlePasswordUpdate = () => {
    if (userConPwd === userPwd.password) {
      setBackDropSts(true);
      axios.put('/users?key=' + localStorage.getItem("apikey"), JSON.stringify(userPwd), {
        headers: { 'Content-Type': 'application/json' }
      })
        .then((response) => {
          alert('Success')
          getUser();
        })
        .catch((error) => {
          alert('Please check your current password')
          setBackDropSts(false);
        })
    } else {
      alert('Please confirm new password')
    }
  }

  const hadleUserGoalChange = (val) => {
    setUserGoal(val);
  }

  const handleDbdModeCheck = (eve) => {
    setUserDbdMode(eve.value);
  }

  const handlePasswordChange = (val, type) => {
    setUserPwd((userPwd) => {
      const tmpUsrPwd = { ...userPwd };
      type === 'current_password' ? tmpUsrPwd.current_password = val : tmpUsrPwd.password = val;
      return tmpUsrPwd;
    })
  }

  const handleConfirmPasswordChange = (val) => {
    setUserConPwd(val)
  }

  const getUser = () => {
    setBackDropSts(true);
    axios.get('/users?key=' + localStorage.getItem("apikey"))
      .then((response) => {
        setUserInfo(response.data.results[0]);
        setUserDbdMode(response.data.results[0].dashboard_mode)
        setUserGoal(response.data.results[0].target_seller_defined)
        setBackDropSts(false);
      })
  }
  useEffect(() => {
    getUser();
  }, []);
  return (
    <>
      <Helmet>
        <title>Profile | Xmas Cookies</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              lg={6}
              md={6}
              xl={12}
              xs={12}
            >
              <Card>
                <CardContent>
                  <Typography
                    style={{ color: '#666666' }}
                    color="textPrimary"
                    variant="body1"
                  >
                    <div>
                      <SettingsLabel>Campaign</SettingsLabel>
                      <FormControl className={formClasses.root}>
                        {userInfo.campaign_name}
                      </FormControl>
                    </div>
                    <div>
                      <SettingsLabel>Territory</SettingsLabel>
                      <FormControl className={formClasses.root}>
                        {userInfo.territory_name}
                      </FormControl>
                    </div>
                    <div>
                      <SettingsLabel>Real name</SettingsLabel>
                      <FormControl className={formClasses.root}>
                        {userInfo.first_name} {userInfo.last_name}
                      </FormControl>
                    </div>
                    <div>
                      <SettingsLabel>Username</SettingsLabel>
                      <FormControl className={formClasses.root}>
                        {userInfo.email}
                      </FormControl>
                    </div>

                    <div>
                      <SettingsLabel>Current password</SettingsLabel>
                      <FormControl className={formClasses.root}>
                        <TextField
                          id="standard-password-input"
                          type="password"
                          onChange={(event) => {
                            handlePasswordChange(event.target.value, 'current_password');
                          }}
                          autoComplete="current-password"
                        />
                      </FormControl>
                    </div>

                    <div>
                      <SettingsLabel>New password</SettingsLabel>
                      <FormControl className={formClasses.root}>
                        <TextField
                          id="standard-password-input"
                          type="password"
                          onChange={(event) => {
                            handlePasswordChange(event.target.value, 'password');
                          }}
                          autoComplete="current-password"
                        />
                      </FormControl>
                    </div>

                    <div>
                      <SettingsLabel>Confirm new password</SettingsLabel>
                      <FormControl className={formClasses.root}>
                        <TextField
                          id="standard-password-input"
                          type="password"
                          onChange={(event) => {
                            handleConfirmPasswordChange(event.target.value);
                          }}
                          autoComplete="current-password"
                        />
                      </FormControl>
                    </div>

                    <div>
                      <FormControl className={formClasses.root}>
                        <Button variant="contained" color="primary" onClick={handlePasswordUpdate}>
                          Save
                        </Button>
                      </FormControl>
                    </div>
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              xl={12}
              xs={12}
            >
              <Card>
                <CardContent>
                  <Typography
                    style={{ color: '#666666' }}
                    color="textPrimary"
                    variant="body1"
                  >
                    <div>
                      <RadioGroup row aria-label="position" name="position" defaultValue={userInfo.dashboard_mode}>
                        <FormControl className={formClasses.root}><Typography
                          style={{ color: '#666666' }}
                          color="textPrimary"
                          variant="h6"
                        >Dashboard mode</Typography></FormControl>

                        <FormControlLabel
                          value="simple"
                          control={<Radio color="primary" checked={userDbdMode === 'simple'} onClick={(eve) => { handleDbdModeCheck(eve.target) }} />}
                          label="Simple"
                          labelPlacement="start"
                        />
                        <FormControlLabel
                          value="advanced"
                          control={<Radio color="primary" checked={userDbdMode === 'advanced'} onClick={(eve) => { handleDbdModeCheck(eve.target) }} />}
                          label="Advanced"
                          labelPlacement="start"
                        />
                      </RadioGroup>
                    </div>
                    <div>
                      <FormControl className={formClasses.root}><Typography
                        style={{ color: '#666666' }}
                        color="textPrimary"
                        variant="h6"
                      >Base target</Typography></FormControl> <FormControl className={formClasses.root}>{userInfo.target1}</FormControl>
                    </div>
                    <div>
                      <FormControl className={formClasses.root}><Typography
                        style={{ color: '#666666' }}
                        color="textPrimary"
                        variant="h6"
                      >Second target</Typography></FormControl> <FormControl className={formClasses.root}>{userInfo.target2}</FormControl>
                    </div>
                    <div>
                      <FormControl className={formClasses.root}><Typography
                        style={{ color: '#666666' }}
                        color="textPrimary"
                        variant="h6"
                      >Third target</Typography></FormControl> <FormControl className={formClasses.root}>{userInfo.target3}</FormControl>
                    </div>
                    <div>
                      <FormControl className={formClasses.root}><Typography
                        style={{ color: '#666666' }}
                        color="textPrimary"
                        variant="h6"
                      >My goal</Typography></FormControl> <FormControl className={formClasses.root}>{userInfo.target_seller_defined}</FormControl>
                    </div>

                    <div>
                      <FormControl className={formClasses.root}><Typography
                        style={{ color: '#666666' }}
                        color="textPrimary"
                        variant="h6"
                      >New goal:	</Typography></FormControl> <FormControl className={formClasses.root}><TextField
                        id="standard-password-input"
                        type="text"
                        value={userGoal}
                        onChange={(event) => {
                          hadleUserGoalChange(event.target.value);
                        }}
                        autoComplete="current-password"
                      /></FormControl>
                    </div>

                    <div>
                      <FormControl className={formClasses.root}>
                        <Button variant="contained" color="primary" onClick={handleGoalUpdate}>
                          Save
                        </Button>
                      </FormControl>
                    </div>
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Backdrop className={classes.backdrop} open={backDropSts}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default Profile;
