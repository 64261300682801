import { createContext, useEffect, useState } from "react";
import { getSKUColumns, getSKUStyleClasses, getSKUs } from "../sku";

export const SKUInfo = createContext({
  info: [],
  columns: [],
  classes: []
});

export const SKUInfoProvider = (props) => {
  const [skuInfo, setSkuInfo] = useState({
    info: [],
    columns: [],
    classes: [],
    sorting: {}
  });

  useEffect(() => {
    getSKUs(localStorage.getItem('apikey')).then((info) => {
      setSkuInfo({
        info,
        columns: getSKUColumns(info),
        classes: getSKUStyleClasses(info),
        sorting: Object.assign({}, ...info.map((i) => ({ [i.code]: i.sorting })))
      });
    }).catch((error) => {
      console.log(error);
    });
  }, []);

  return (
    <SKUInfo.Provider value={{...skuInfo}}>
      {props.children}
    </SKUInfo.Provider>
  )
}
