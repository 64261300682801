import {
  Backdrop,
  CircularProgress
} from '@mui/material';

export const StyledBackdrop = (props) => {
  const tmpSx = {...(props.sx || {}), ...{sx: { zIndex: 1000, color: 'white' }}};
  const tmpProps = {...props, ...tmpSx};

  return (
    <Backdrop {...tmpProps}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};
