import { Redirect } from "react-router-dom";

import Login from './pages/Login';
import MainLayout from './components/MainLayout';
import Dashboard from './pages/Dashboard';
import DashboardIcon from "@material-ui/icons/Dashboard";
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import AssignmentIcon from '@material-ui/icons/Assignment';
import SettingsIcon from '@material-ui/icons/Settings';
import ReviewOrders from './pages/ReviewOrders';
import OrderDetail from './pages/OrderDetail';
import ReviewDeliveries from './pages/ReviewDeliveries';
import EndOfDay from './pages/EndOfDay';
import Shipments from './pages/Shipments'
import Transfers from './pages/Transfers'
import OrderReport from './pages/OrderReport'
import DeliveryReport from './pages/DeliveryReport'
import QuickSales from './pages/QuickSales'
import Profile from './pages/Profile';
import ScanBackReport from './pages/ScanBackReport';
import FinanceReport from './pages/FinanceReport';
import StockReport from './pages/StockReport'
import FdoReport from './pages/FdoReport'
import Embed from "./components/Embed";
// import Loans from './pages/Loans'

const routes = [
  {
    path: "/",
    type: "link",
    component: () => {
      return <Redirect
        to={{
          pathname: "/dashboard",
        }}
      />
    },
    routes: []
  },
  {
    path: "/login",
    type: "link",
    component: <Login />,
    routes: []
  },
  {
    path: "/tasks/revieworders/:id",
    type: "link",
    component: <MainLayout children={OrderDetail} title="Review Orders"/>,
    routes: []
  },
  {
    path: "/dashboard",
    type: "list",
    title: "Dashboard",
    icon: <DashboardIcon />,
    component: <MainLayout children={Dashboard} title="Dashboard" />,
    routes: []
  },
  {
    path: "/tasks",
    type: "menu",
    title: "My Day",
    icon: <FormatListBulletedIcon />,
    component: () => {
      return <Redirect
        to={{
          pathname: "/tasks/revieworders",
        }}
      />
    },
    routes: [
      {
        path: "/tasks/revieworders",
        type: "list",
        title: "Review Orders",
        component: <MainLayout children={ReviewOrders} title="Review Orders"/>,
      },
      {
        path: "/tasks/deliveryrun",
        type: "list",
        title: "Review Deliveries",
        component: <MainLayout children={ReviewDeliveries} title="Review Deliveries"/>,
      },
      {
        path: "/tasks/endofday",
        type: "list",
        title: "Daily Figures",
        component: <MainLayout children={EndOfDay} title="Daily Figures"/>,
      },
    ]
  },
  {
    path: "/stock",
    type: "menu",
    title: "Manage Stock",
    icon: <LocalShippingIcon />,
    component: () => {
      return <Redirect
        to={{
          pathname: "/stock/shipments",
        }}
      />
    },
    routes: [
      {
        path: "/stock/shipments",
        type: "list",
        title: "Shipments",
        component: <MainLayout children={Shipments} title="Shipments"/>,
      },
      {
        path: "/stock/transfers",
        type: "list",
        title: "Transfers",
        component: <MainLayout children={Transfers} title="Transfers"/>,
      },
      {
        path: "/tasks/loans",
        type: "list",
        title: "Loans",
        component: <MainLayout children={()=>(<div>Loans</div>)} title="Loans"/>,
      },
    ]
  },
  {
    path: "/report",
    type: "menu",
    title: "Reports",
    icon: <AssignmentIcon />,
    component: () => {
      return <Redirect
        to={{
          pathname: "/report/order",
        }}
      />
    },
    routes: [
      {
        path: "/report/order",
        type: "list",
        title: "Order Report",
        component: <MainLayout children={OrderReport} title="Order Report"/>,
      },
      {
        path: "/report/delivery",
        type: "list",
        title: "Delivery Report",
        component: <MainLayout children={DeliveryReport} title="Delivery Report"/>,
      },
      {
        path: "/report/upsale",
        type: "list",
        title: "Upsale Report",
        component: <MainLayout children={()=>(<div>Upsale Report</div>)} />,
      },
      {
        path: "/report/quicksale",
        type: "list",
        title: "Quick Sales",
        component: <MainLayout children={QuickSales} title="Quick Sales"/>,
      },
      {
        path: "/report/banking",
        type: "list",
        title: "Finance Report",
        component: <MainLayout children={FinanceReport} title="Finance Report"/>,
      },
      {
        path: "/report/stockonhand",
        type: "list",
        title: "Stock Report",
        component: <MainLayout children={StockReport} title="Stock Report"/>,
      },
      {
        path: "/report/faxback",
        type: "list",
        title: "Scanbacks",
        component: <MainLayout children={ScanBackReport} title="Scanbacks"/>,
      },
      {
        path: "/report/saledelivery",
        type: "list",
        title: "FDO Report",
        component: <MainLayout children={FdoReport} title="FDO Report"/>,
      }
    ]
  },
  {
    path: "/mysettings",
    type: "list",
    title: "My Settings",
    icon: <SettingsIcon />,
    component: <MainLayout children={Profile} title='My Settings' />,
    routes: []
  },
  {
    path: '/embed/:apiKey',
    type: "list",
    component: <Embed />,
    routes: []
  }
];

export default routes;
