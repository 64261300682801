// This is a comment
// This is another comment

import { Backdrop, CircularProgress, useTheme } from '@material-ui/core';
import {
  useHistory,
} from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";
import { DataGrid } from '@mui/x-data-grid';
import { makeStyles } from '@material-ui/styles';
import { useContext, useState } from 'react';

import 'date-fns';
import { classNameForSKU } from '../sku';
import Report from '../components/Report';
import { SKUInfo } from '../components/SKUInfo';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .super-app-theme--header': {
      backgroundColor: '#f0f0f0',
      fontWeight: 'bold',
      fontSize: '10px'
    },
    '& .super-app-theme--ordered': {
      backgroundColor: 'rgb(164, 237, 181)',
      fontWeight: 'bold',
      fontSize: '10px'
    },
    '& .super-app-theme--delivered': {
      backgroundColor: 'rgb(249, 172, 185)',
      fontWeight: 'bold',
      fontSize: '10px'
    },
    '& .super-app-theme--quicksales': {
      backgroundColor: 'rgb(173, 180, 240)',
      fontWeight: 'bold',
      fontSize: '10px'
    },
    '& .super-app-theme--column-group': {
      fontWeight: 'bold',
      fontSize: '12px',
      textTransform: 'uppercase',
    }
  }, backdrop: {
    zIndex: theme.zIndex.drawer - 2,
    color: '#fff',
  },
}));

const formStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    verticalAlign: 'middle'
  }
}));

const FdoReport = () => {
  let history = useHistory();
  const classes = useStyles();
  const formClasses = formStyles();

  const skuInfo = useContext(SKUInfo);

  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [columnGroups, setColumnGroups] = useState([]);
  const [reportDownLoad, setReportDownLoad] = useState('');
  const [showBackdrop, setShowBackdrop] = useState(false);

  const handleReportRun = (dates) => {
    setShowBackdrop(true);

    axios.post(
      `/reports/run/fdo_report?key=${localStorage.getItem("apikey")}`,
      {
        "date_range": dates.start.toLocaleDateString('en-CA') + "," + dates.end.toLocaleDateString('en-CA')
      },
      {
        headers: { 'content-type': 'application/json' }
      }
    ).then((response) => {
      let tmpCol = [];
      let tmpGroups = [
        {
          groupId: 'ordered',
          headerClassName: 'super-app-theme--ordered super-app-theme--column-group',
          headerAlign: 'center',
          children: [],
        },
        {
          groupId: 'delivered',
          headerClassName: 'super-app-theme--delivered super-app-theme--column-group',
          headerAlign: 'center',
          children: []
        },
        {
          groupId: 'quicksales',
          headerClassName: 'super-app-theme--quicksales super-app-theme--column-group',
          headerAlign: 'center',
          children: []
        }
      ];

      const re = /^((?:x[a-z]{2})|total)_(?<columnGroup>ordered|delivered|quicksales)$/;
      for (var colKey in response.data.headers) {
        // const headerSkuClass = classNameForSKU(colKey, false);
        const sku = colKey.split('_')[0].toUpperCase();
        console.log(`Got sku code :: ${sku}`);
        const headerSkuClass = `sku-header-${sku}`
        const cellClass = `sku-${sku}`;
        let headerClass = 'super-app-theme--header';

        if (re.test(colKey)) {
          const matches = colKey.match(re);
          const columnGroup = matches.groups.columnGroup; // ordered | delivered | quicksales
          const columnGroupIndex = tmpGroups.findIndex((obj) =>
            obj.groupId.toLowerCase() === columnGroup.toLowerCase()
          );

          if (columnGroupIndex > -1) {
            tmpGroups[columnGroupIndex].children.push(
              { field: colKey }
            )
          }

          headerClass = `super-app-theme--${columnGroup}`;
        }

        tmpCol.push({
          field: colKey,
          headerName: response.data.headers[colKey].replace(' (ordered)', '').replace(' (delivered)', '').replace(' (quicksales)', ''),
          headerClassName: `${headerClass} ${headerSkuClass}`,
          cellClassName: cellClass,
          sortable: (colKey !== 'date' ? false : true),
          headerAlign: 'center',
          align: 'center'
        })
      }

      let tmpRow = response.data.rows;
      for (var rowKey in tmpRow) {
        tmpRow[rowKey].id = parseInt(rowKey)
      }

      setRows(tmpRow);
      setColumns(tmpCol);
      setColumnGroups(tmpGroups);
      setReportDownLoad(response.data.links.csv);
      setShowBackdrop(false);
    }).catch(function (error) {
      console.log(error);
    });
  }

  return (
    <>
      <Helmet>
        <title>FDO Report | Xmas Cookies</title>
      </Helmet>
      <Report onRun={handleReportRun}
        downloadURL={reportDownLoad}>
        <div style={{ overflowX: 'scroll', height: 800 }} className={classes.root}>
          <DataGrid
            experimentalFeatures={{ columnGrouping: true }}
            rows={rows}
            columns={columns}
            pageSize={100}
            disableSelectionOnClick={true}
            columnGroupingModel={columnGroups}
            sx={{ border: 'none' }}
          />
        </div>
      </Report>
      <Backdrop className={classes.backdrop} open={showBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default FdoReport;
