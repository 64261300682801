import { useState } from 'react';
import { FormControl, Button, ThemeProvider } from '@mui/material';
import { ReportRange, getCampaignStartDate, getCampaignEndDate, getRangeDates } from '../Date';
import DateRangePicker from './DateRangePicker';
import { CloudDownload } from '@material-ui/icons';
import theme from '../theme/index';

const PaddedControl = (props) => {
  return (
    <FormControl sx={{ m: props.margin || '4px' }} {...props}>
    </FormControl>
  );
}

const ReportControls = (props) => {
  const [dates, setDates] = useState(getRangeDates(ReportRange.Campaign));

  const handleDatesChanged = (d) => {
    if (props.onDatesChange) {
      props.onDatesChange(d);
    }

    setDates(d);
  };

  const handleDateRangeChanged = (range) => {
    const dates = getRangeDates(range);
    handleDatesChanged(dates);
  }

  const handleRun = () => {
    if (props.onRun) {
      props.onRun(dates);
    }
  }

  const handleDownload = () => {
    if (props.downloadURL) {
      window.open(`${props.downloadURL}?key=${localStorage.getItem("apikey")}`)
    }
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        {
          !props.disableDateControl && (
            <span>
              <DateRangePicker
                startDate={dates.start}
                endDate={dates.end}
                minDate={getCampaignStartDate()}
                maxDate={getCampaignEndDate()}
                onChange={handleDatesChanged} />
            </span>) || null
        }
        
        <span>
          <PaddedControl>
            <Button variant="contained" color="primary" onClick={handleRun}>
              Run
            </Button>
          </PaddedControl>
          {
            !props.disableDateControl && (
              <span>
                <PaddedControl>
                  <Button variant="outlined" color="primary" onClick={() => handleDateRangeChanged(ReportRange.Today)}>
                    Today
                  </Button>
                </PaddedControl>

                <PaddedControl>
                  <Button variant="outlined" color="primary" onClick={() => handleDateRangeChanged(ReportRange.Yesterday)}>
                    Yesterday
                  </Button>
                </PaddedControl>

                <PaddedControl>
                  <Button variant="outlined" color="primary" onClick={() => handleDateRangeChanged(ReportRange.ToDate)}>
                    To-Date
                  </Button>
                </PaddedControl>

                <PaddedControl>
                  <Button variant="outlined" color="primary" onClick={() => handleDateRangeChanged(ReportRange.Campaign)}>
                    Campaign
                  </Button>
                </PaddedControl>
              </span>) || null
          }
          <PaddedControl>
            <Button
              variant="contained"
              color="primary"
              onClick={handleDownload}
              disabled={!props.downloadURL}
              startIcon={<CloudDownload />}
            >
              Save
            </Button>
          </PaddedControl>
        </span>
      </ThemeProvider>
    </>
  );
};

export default ReportControls;
