import React, { useEffect } from 'react';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography
} from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';import { red, yellow } from '@material-ui/core/colors';

const Budget = (props) => {
  return <Card
    style={{ height: '100%' }}
    {...props}
  >
    <CardContent>
      <Grid
        container
        spacing={3}
        style={{ justifyContent: 'space-between' }}
      >
        <Grid item >
          <Typography
            color="textSecondary"
            gutterBottom
            variant="h6"
          >
            QuickSales
          </Typography>
          <Typography
            color="textPrimary"
            variant="h3"
          >
            {props.dashdata ? props.dashdata['total_quicksales'] : 0}
          </Typography>
        </Grid>
        <Grid item>
          <Avatar
            style={{
              backgroundColor: yellow[300],
              height: 56,
              width: 56
            }}
          >
            <ArrowUpwardIcon />
          </Avatar>
        </Grid>
      </Grid>
      {/* <Box
        style={{
          pt: 2,
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <ArrowDownwardIcon style={{ color: red[900] }} />
        <Typography
          style={{
            color: red[900],
            mr: 1
          }}
          variant="body2"
        >
          12%
        </Typography>
        <Typography 
          color="textSecondary"
          variant="caption"
          style={{marginLeft:'4pt'}}
        >
          Since last week
        </Typography>
      </Box> */}
    </CardContent>
  </Card>
};

export default Budget;
