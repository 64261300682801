import React from 'react';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/core/styles';
import logo from '../xc-logo.jpg';
import {
  CardContent,
  Button,
  Container,
  Link,
  TextField,
  Typography
} from '@material-ui/core';
import axios from "axios";

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#FFFFFF'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: '#666666'
  },
}));

const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      v{process.env.REACT_APP_RELEASE_TAG}&nbsp;&nbsp;&nbsp;
      {`© ${new Date().getFullYear()} Cookie Time Limited`}
    </Typography>
  );
}

export default function Login() {
  const classes = useStyles();
  const [userName, setUserName] = React.useState('');
  const [userPwd, setUserPwd] = React.useState('');
  const [disabledSignIn, setDisabledSignIn] = React.useState(false);
  const handleKeyUp = (code) => {
    if (code === 13) {
      handleButtonClick();
    }
  }

  const handleButtonClick = () => {
    setDisabledSignIn(true);
    axios.post('/auth', {
      email: userName,
      password: userPwd
    }).then((response) => {
      const apiKey = response.data.apikey;
      localStorage.setItem("apikey", apiKey);
      localStorage.setItem("user", userName);

      axios.get(`/users?key=${apiKey}`)
        .then((resp) => {
          const startDate = new Date(resp.data.results[0].campaign_reporting_first_date);
          const endDate = new Date(resp.data.results[0].campaign_reporting_last_date);

          localStorage.setItem('campaignStart', startDate.toJSON());
          localStorage.setItem('campaignEnd', endDate.toJSON());

          window.location.href = "/";
        }).catch((error) => {
          console.log(error);
        });
    }).catch((error) => {
      alert(error.response?.data?.message || 'An unknown error occurred');
      setDisabledSignIn(false);
    });
  };
  return (

    <Container component="main" maxWidth="xs">
      <Helmet>
        <title>Login | Xmas Cookies</title>
      </Helmet>
      <CardContent className={classes.paper}>
        <img src={logo} alt="Xmas Cookies" />

        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="userName"
            label="E-Mail"
            name="userName"
            autoComplete="text"
            autoFocus
            onChange={(event) => setUserName(event.target.value)}
            onKeyUp={(event) => {handleKeyUp(event.keyCode)}}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={(event) => setUserPwd(event.target.value)}
            onKeyUp={(event) => {handleKeyUp(event.keyCode)}}
          />

          <Link href="#" variant="body2" onClick={()=>{
                  alert('Please contact HQ to reset your passwrod');
                }}>
            Forgot password?
          </Link>
          
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={disabledSignIn}
            onClick={handleButtonClick}
          >
            Login
          </Button>
        </form>

        <Copyright />
      </CardContent>
    </Container>
  );

};
