import Section from "../Section"
import SKUList from "../SKUList";

const QuickSales = (props) => {
  return (
    <Section
      title="Quick Sales"
      {...props}
    >
      <SKUList lines={props.lines.filter((row) => row.type === "quicksales")} />
    </Section>
  );
};

export default QuickSales;
