import React, { useEffect } from 'react';
import axios from "axios";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme,
  colors
} from '@material-ui/core';

import { PureComponent } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Brush,
  BarChart,
  Bar,
  Cell,
  ResponsiveContainer,
} from 'recharts';

import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

const Sales = (props) => {
  const [lineData, setLineData] = React.useState([]);

  const lineColors = [
    '#3977AF',
    '#EF8536',
    '#529D3E',
    '#C53932',
    '#8D6BB8',
    '#000000'
  ]

  const lineChart = () => { 
    for (var i in lineData[0]) {
      console.log(i);
    }
    
    // return (
    // <Line></Line>
  }

  useEffect(() => {
    axios.get('/stocklevels?key=' + localStorage.getItem("apikey"))
      .then((response) => {
        const data = [];
        for (var key of Object.keys(response.data)) {
          data.push(Object.assign({
            name: new Date(key).toLocaleDateString("en-NZ", {
              weekday: 'short',
              day: 'numeric',
              month: 'short'
            }),
          },response.data[key]))
        }
        setLineData(data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  return (
    <Card {...props}>
      <CardHeader
        action={(
          <Button
            endIcon={<CloudDownloadIcon />}
            size="small"
            variant="text"
          >
            Download
          </Button>
        )}
        title="Stock On Hand"
      />
      <Divider />
      <CardContent>
        <Box
          style={{
            height: 400,
            position: 'relative'
          }}
        >
          <ResponsiveContainer width="100%" height='100%'>

            <LineChart
              data={lineData}
              margin={{
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              {
                lineData.length && Object.keys(lineData[0]).map((val,i) => {
                  if (val !== 'name') {
                    return <Line key={i} type="monotone" dataKey={val} stroke={lineColors[i]} />
                  }
                })
                
              }
              <Brush />
            </LineChart>
          </ResponsiveContainer>

        </Box>
      </CardContent>
    </Card>
  );
};

export default Sales;
