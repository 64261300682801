import { ExpandMore } from "@mui/icons-material";
import { AccordionActions, FormControlLabel, Checkbox, Typography, Container } from "@mui/material";
import { styled } from '@mui/material/styles';

import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,

  '&:not(:last-child)': {
    borderBottom: 0,
  },

  '&:before': {
    display: 'none'
  }
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary {...props} />
))(({ _theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, 0.03)',
  minHeight: 56
}))

const AccordionDetails = styled((props) => (
  <MuiAccordionDetails {...props} />
))(({ theme }) => ({
  padding: theme.spacing(2)
}));

const Section = (props) => {
  const { expanded, onExpand, error, title, ...rest } = props;
  
  return (
    <Accordion expanded={expanded} onChange={onExpand}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-label="Expand">
        <Typography style={(error ? { color: "red" } : {})}>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {props.children}
      </AccordionDetails>
      <AccordionActions>
        <div style={{ width: "100%", textAlign: "left" }}>
          <FormControlLabel
            aria-label="Acknowledge"
            onClick={(event) => event.stopPropagation()}
            onFocus={(event) => event.stopPropagation()}
            control={
              <Checkbox
                checked={props.isComplete}
                disabled={props.isComplete}
                onChange={(event) => {
                  if (props.onConfirm) {
                    props.onConfirm(event.target.checked);
                  }
                }}
              />
            }
            label={props.checkLabel || "Please check to confirm figures."}
          />
        </div>
      </AccordionActions>
    </Accordion>
  );
};

export default Section;
