import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, Stack } from '@mui/material';
import { useState } from 'react';

const BankDialog = (props) => {
  const [bankCash, setBankCash] = useState(0);
  const [bankNote, setBankNote] = useState("");

  const handleConfirm = () => {
    if (props.onConfirm) {
      props.onConfirm(bankCash, bankNote);
    }
  };

  return (
    <Dialog open={props.open} onClose={props.onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Banked Note</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <TextField
            id="outlined-multiline-static"
            fullWidth
            label="Banked Cash"
            value={bankCash}
            onChange={(event) => { setBankCash(Number(event.target.value)) }}
            variant="outlined"
          />
          <TextField
            id="outlined-multiline-static"
            label="Note"
            multiline
            fullWidth
            rows={4}
            value={bankNote}
            onChange={(event) => { setBankNote(event.target.value) }}
            variant="outlined"
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleConfirm} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BankDialog;
