import { Container } from '@mui/material';
import Section from '../Section';
import SKUList from '../SKUList';
import { SKUInfoProvider } from '../../SKUInfo';

const BucketTotals = (props) => {
  return (
    <SKUInfoProvider>
      <Section
        title="Bucket Totals"
        {...props}
      >
        <Container sx={{display: "flex", overflowX: "auto", minWidth: "100%", margin: "0 auto" }}>
          <SKUList sx={{flex: 1, minWidth: 300 }}
            lines={props.lines.filter((row) => row.type === 'added_to_orders')}
            label='Added to Orders'
          />

          <SKUList sx={{flex: 1, minWidth: 300 }}
            lines={props.lines.filter((row) => row.type === 'removed_from_orders')}
            label='Removed from Orders'
          />

          <SKUList sx={{flex: 1, minWidth: 300 }}
            lines={props.lines.filter((row) => row.type === "delivered")}
            label='Deliveries (All)'
          />
        </Container>
      </Section>
    </SKUInfoProvider>
  );
};

export default BucketTotals;
