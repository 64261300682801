import React, { useEffect } from 'react';
import {
  Box,
  Container,
  Grid, Avatar, Card, CardHeader, Divider, CardContent, Backdrop, CircularProgress, makeStyles,Typography
} from '@material-ui/core';

import { Helmet } from "react-helmet";
import Bucket from "../components/dashboard/Bucket";
import BucketPerOrder from "../components/dashboard/BucketPerOrder"
import Deliver from "../components/dashboard/Deliver";
import Quick from "../components/dashboard/Quick";
import BucketsSold from "../components/dashboard/BucketsSold";
import axios from "axios";
import KeyPerformance from "../components/dashboard/KeyPerformance";
import StockLine from "../components/dashboard/StockLine";
import OrdersPerOrg from "../components/dashboard/OrdersPerOrg";
import ProgressOrgsTarget from "../components/dashboard/ProgresOrgsTarget";
import ProgressBaseTarget from "../components/dashboard/ProgressBaseTarget";
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import { purple } from '@material-ui/core/colors';


const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer - 2,
    color: '#fff',
  },
}));

const Dashboard = () => {
  const [dash, setDash] = React.useState([]);
  const classes = useStyles();
  const [backDropSts, setBackDropSts] = React.useState(true);
  const [userDbdMode, setUserDbdMode] = React.useState('');
  const [rankRegion, setRankRegion] = React.useState('')

  const ordinal_suffix_of = (i) => {
    var j = i % 10,
      k = i % 100;
    if (j === 1 && k !== 11) {
      return i + "st";
    }
    if (j === 2 && k !== 12) {
      return i + "nd";
    }
    if (j === 3 && k !== 13) {
      return i + "rd";
    }
    return i + "th";
  }
  useEffect(() => {
    axios.get('/sellerdashboards?key=' + localStorage.getItem("apikey"))
      .then((response) => {
        setBackDropSts(false);
        console.log(response.data.dashboards.main_dash);
        console.log(localStorage.getItem("apikey"));
        setDash(response.data.dashboards.main_dash);
      })
      .catch(function (error) {
        console.log(error);
      });

    axios.get('/users?key=' + localStorage.getItem("apikey"))
      .then((response) => {
        setUserDbdMode(response.data.results[0].dashboard_mode)
        setRankRegion(response.data.results[0].rank_in_region)
      })

  }, []);
  return (
    <>
      <Helmet>
        <title>Dashboard | Xmas Cookies</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              lg={3}
              sm={6}
              xl={3}
              xs={12}
            >
              <Bucket dashdata={dash} />
            </Grid>
            <Grid
              item
              lg={3}
              sm={6}
              xl={3}
              xs={12}
            >
              <Deliver dashdata={dash} />
            </Grid>
            <Grid
              item
              lg={3}
              sm={6}
              xl={3}
              xs={12}
            >
              <Quick dashdata={dash} />
            </Grid>
            <Grid
              item
              lg={3}
              sm={6}
              xl={3}
              xs={12}
            >
              <BucketsSold dashdata={dash} />
            </Grid>
            <Grid
              item
              lg={userDbdMode === 'advanced' ? 9 : 12}
              md={userDbdMode === 'advanced' ? 9 : 12}
              xl={userDbdMode === 'advanced' ? 9 : 12}
              xs={12}
            >
              <KeyPerformance dashdata={dash} />
            </Grid>
            {userDbdMode === 'advanced' && <Grid
              item
              lg={3}
              md={3}
              xl={3}
              xs={12}
            >
              <Card>
                <CardHeader

                  title="Seller Rank In Campaign"
                />
                <Divider />
                <CardContent>
                  <Box
                    style={{
                      height: 200,
                      position: 'relative',
                      textAlign: 'center'
                    }}
                  >
                    <Avatar style={{
                      backgroundColor: "#ff0000",
                      height: 210,
                      width: 210,
                      margin: 'auto'
                    }}>
                      <span style={{ fontSize: '80px' }}>{dash ? ordinal_suffix_of(dash.seller_rank_in_campaign) : ''}</span>
                    </Avatar>
                  </Box>
                </CardContent>
              </Card>

            </Grid>}
            <Grid item xs>
              <BucketPerOrder dashdata={dash} />
            </Grid>
            <Grid item xs>
              <OrdersPerOrg dashdata={dash} />
            </Grid>
            <Grid item xs>
              <ProgressOrgsTarget dashdata={dash} />
            </Grid>
            <Grid item xs>
              <ProgressBaseTarget dashdata={dash} />
            </Grid>
            {userDbdMode === 'advanced' && <Grid item xs>
            <Card
    style={{ height: '100%' }}
  >
    <CardContent>
      <Grid
        container
        spacing={3}
        style={{ justifyContent: 'space-between' }}
      >
        <Grid item >
          <Typography
            color="textSecondary"
            gutterBottom
            variant="h6"
          >
            Rank in region
          </Typography>
          <Typography
            color="textPrimary"
            variant="h3"
          >
            {rankRegion ? ordinal_suffix_of(rankRegion) : ''}
          </Typography>
        </Grid>
        <Grid item>
          <Avatar
            style={{
              backgroundColor: purple[900],
              height: 56,
              width: 56
            }}
          >
            <FormatListNumberedIcon />
          </Avatar>
        </Grid>
      </Grid>
      {/* <Box
        style={{
          pt: 2,
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <ArrowUpwardIcon style={{ color: green[900] }} />
        <Typography
          style={{
            color: green[900],
            mr: 1
          }}
          variant="body2"
        >
          12%
        </Typography>
        <Typography 
          color="textSecondary"
          variant="caption"
          style={{marginLeft:'4pt'}}
        >
          Since last week
        </Typography>
      </Box> */}
    </CardContent>
  </Card>
            </Grid>}
            <Grid
              item
              lg={12}
              md={12}
              xl={12}
              xs={12}
            >
              <StockLine />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Backdrop className={classes.backdrop} open={backDropSts}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default Dashboard;
