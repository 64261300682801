import { useState, useEffect, useReducer, useContext } from 'react';
import axios from "axios";
import {
  Box,
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Backdrop, CircularProgress, FormControl
} from '@material-ui/core';

import {
  useHistory,
} from "react-router-dom";
import { Helmet } from "react-helmet";
import { makeStyles } from '@material-ui/styles';

import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import SurveyDialog from '../components/EndOfDay/SurveyDialog';
import MessageDialog from '../components/MessageDialog';
import BucketTotals from '../components/EndOfDay/sections/BucketTotals';
import QuickSales from '../components/EndOfDay/sections/QuickSales';
import Takings from '../components/EndOfDay/sections/Takings';
import StockCheck from '../components/EndOfDay/sections/StockCheck';
import Banked from '../components/EndOfDay/sections/Banked';
import { SKUInfo } from '../components/SKUInfo';
import { StyledBackdrop } from '../components/StyledBackdrop';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .super-app-theme--header': {
      backgroundColor: '#f0f0f0',
      fontWeight: 'bold',
    },
  }, backdrop: {
    zIndex: theme.zIndex.drawer - 2,
    color: '#fff',
  },
}));

const formStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    verticalAlign: 'middle'
  }
}));

const EODStage = {
  BucketTotals: Symbol('bucket-totals'),
  QuickSales: Symbol('quick-sales'),
  Takings: Symbol('takings'),
  StockCheck: Symbol('stock-check'),
  Banked: Symbol('banked')
};

const ReducerAction = {
  Banking: Symbol('banking'),
  StockError: Symbol('stock-error'),
  ActualTakings: Symbol('actual-takings'),
  TakingsNote: Symbol('takings-note'),
  TakingsError: Symbol('takings-error'),
  StockNote: Symbol('stock-note'),
  SurveyAnswers: Symbol('survey-answers'),
  Lines: Symbol('lines'),
  Overwrite: Symbol('overwrite'),
};

const StockWrongness = {
  Smally: Symbol('smally-wrong'),
  Bigly: Symbol('bigly-wrong'),
}

const figuresReducer = (state, action) => {
  const { kind, value } = action;
  if (kind === ReducerAction.Overwrite) {
    // Used to populate the daily figures initially; just
    // short-circuit and return the new value
    return { ...state, ...action.value };
  }

  let newState = { ...state };

  switch (kind) {
    case ReducerAction.Banking: {
      newState.banked_cash = value.banked_cash;
      newState.banking_note = value.banking_note;
      break;
    }

    case ReducerAction.StockError: {
      if (!value) {
        newState.is_stock_wrong = 0;
        newState.is_stock_bigly_wrong = 0;
        break;
      }

      if (value === StockWrongness.Bigly) {
        newState.is_stock_bigly_wrong = 1;
      }

      newState.is_stock_wrong = (value === StockWrongness.Bigly || value === StockWrongness.Smally) ? 1 : 0;
      newState.is_stock_bigly_wrong = value === StockWrongness.Bigly ? 1 : 0;
      break;
    }

    case ReducerAction.ActualTakings: {
      newState.received_cash = value.received_cash;
      newState.received_eftpos = value.received_eftpos;
      break;
    }

    case ReducerAction.TakingsNote: {
      newState.takings_note = value;
      break;
    }

    case ReducerAction.TakingsError: {
      newState.is_takings_wrong = value;
      break;
    }

    case ReducerAction.StockNote: {
      newState.stock_note = value;
      break;
    }

    case ReducerAction.SurveyAnswers: {
      newState.happiness_level = value.happiness_level;
      newState.survey_question_1 = value.survey_question_1;
      newState.survey_question_2 = value.survey_question_2;
      newState.is_callback_requested = value.is_callback_requested;
      break;
    }

  case ReducerAction.Lines: {
    newState.lines = value;
    break;
  }

    default:
      throw new Error("Invalid reducer action");
  }

  return newState;
}

const EndOfDay = () => {
  let history = useHistory();
  const classes = useStyles();
  const formClasses = formStyles();

  const [completedStages, setCompletedStages] = useState(new Set());

  const skuInfo = useContext(SKUInfo);

  const setCompleted = (stage) => {
    setCompletedStages((stages) => {
      const tmpStages = new Set(stages);

      if (Array.isArray(stage)) {
        stage.forEach((s) => {
          tmpStages.add(s);
        });
      } else {
        tmpStages.add(stage);
      }

      return tmpStages;
    });
  };

  const isCompleted = (stage) => {
    if (Array.isArray(stage)) {
      return stage.reduce((accumulator, s) => (
        accumulator && completedStages.has(s)
      ), true);
    }

    return completedStages.has(stage);
  };

  const clearCompleted = (stage) => {
    if (Array.isArray(stage)) {
      setCompletedStages((ss) => {
        let tmpStages = new Set(ss);

        stage.forEach((s) => {
          tmpStages.delete(s);
        });

        return tmpStages;
      });
    } else if (stage === undefined) {
      setCompletedStages(new Set());
    } else {
      setCompletedStages((ss) => {
        let tmpStages = new Set(ss);
        tmpStages.delete(stage);

        return tmpStages;
      })
    }
  }

  const [activePanel, setActivePanel] = useState(EODStage.BucketTotals);

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [dateRange, setDateRange] = useState([]);
  const [showBackdrop, setShowBackdrop] = useState(false);

  const [dailyFigures, dispatchFigures] = useReducer(figuresReducer, {
    takings_cash: 0,
    takings_eftpos: 0,
    lines: [],
    banked_cash: 0,
    banking_note: "",
    is_stock_bigly_wrong: 0,
    is_stock_wrong: 0,
    received_cash: 0,
    received_eftpos: 0,
    takings_note: "",
    is_takings_wrong: false,
    stock_note: "",
    happiness_level: 0,
    survey_question_1: "",
    survey_question_2: "",
    is_callback_requested: false
  });

  const [expectedFigures, setExpectedFigures] = useState({lines: []});

  const setBanking = (value) => {
    dispatchFigures({
      kind: ReducerAction.Banking, value: value
    });
  }

  const setStockError = (error) => {
    dispatchFigures({ kind: ReducerAction.StockError, value: error });
  };

  const setActualTakings = (received_cash, received_eftpos) => {
    dispatchFigures({
      kind: ReducerAction.ActualTakings, value: { received_cash, received_eftpos }
    });
  };

  const setTakingsNote = (note) => {
    dispatchFigures({ kind: ReducerAction.TakingsNote, value: note });
  };

  const setTakingsError = (error) => {
    dispatchFigures({ kind: ReducerAction.TakingsError, value: error });
  };

  const setStockNote = (note) => {
    dispatchFigures({ kind: ReducerAction.StockNote, value: note });
  };

  const setSurveyAnswers = (answers) => {
    dispatchFigures({ kind: ReducerAction.SurveyAnswers, value: answers });
  };

  const setFigures = (figures) => {
    dispatchFigures({ kind: ReducerAction.Overwrite, value: { ...figures } })
  }

  const setLines = (lines) => {
    dispatchFigures({ kind: ReducerAction.Lines, value: [...lines] })
  }

  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [showSurveyDialog, setShowSurveyDialog] = useState(false);
  const [showCompletionDialog, setShowCompletionDialog] = useState(false);
  const [errorDetails, showError] = useState(null);
  const [continueClicked, setContinueClicked] = useState(false);

  const [submissionStatus, setSubmissionStatus] = useState('');

  const handleSurveyDialogClose = () => {
    setShowSurveyDialog(false);
    setContinueClicked(false);
  };

  const getDateRange = () => {
    axios.get(`/dailyfigures?key=${localStorage.getItem("apikey")}`)
      .then((response) => {
        setDateRange(response.data);
      }).catch((error) => {
	      showError({
	        title: "Error",
	        message: "Failed to get date range",
	      });

        console.log(error);
      });
  }

  const handleDateChange = (date) => {
    // Hack: if selected date is junk just use the current date
    const d = date instanceof Date && !isNaN(date) ? date : new Date();
    
    getDateRange();
    setSelectedDate(d);
  };

  const handleSurveySave = (surveyAnswers) => {
    setShowBackdrop(true);
    setShowSurveyDialog(false);
    setSurveyAnswers(surveyAnswers);

    // Reducer state won't update until the next render, so we
    // manually compute the new state here
    const figures = figuresReducer(dailyFigures, { kind: ReducerAction.SurveyAnswers, value: surveyAnswers });

    axios.post(
      `/dailyfigures/date/${selectedDate.toLocaleDateString('en-CA')}?key=${localStorage.getItem("apikey")}`,
      figures
    ).then((_response) => {
      setShowBackdrop(false);
      setShowCompletionDialog(true);
    }).catch((error) => {
      setContinueClicked(false);
      showError({
	      title: "Error",
	      message: error.response.data.error || error.message
      });

      console.log(error.message);
    }).finally(() => {
      setShowBackdrop(false);
    });
  }

  const handleSubmit = () => {
    setHasSubmitted(true);
    setContinueClicked(true);

    if (isCompleted([EODStage.BucketTotals, EODStage.QuickSales, EODStage.Takings, EODStage.StockCheck])) {
      setShowSurveyDialog(true);
    } else {
      setShowBackdrop(false);
      setContinueClicked(false);
    }
  }

  const handleFinished = () => {
    setShowCompletionDialog(false);
    history.push("/dashboard");
  }

  const handleFailure = (_errorMessage) => {
    setHasSubmitted(false);
    clearCompleted();
  }

  const getDailyFigures = (date) => {
    setShowBackdrop(true);
    axios.get(`/dailyfigures/date/${date}?key=${localStorage.getItem("apikey")}`)
      .then((response) => {
        setShowBackdrop(false);
        
        if (response.data.success === false) {
          handleFailure(response.data.error);
          return;
        }

        let expected_figures = response.data.expected_figures;
        let seller_figures = response.data.seller_figures;

        setFigures(seller_figures);
        setExpectedFigures(expected_figures);

        setSubmissionStatus(response.data.status);

        if (response.data.status === 'completed') {
          setHasSubmitted(false);
          setContinueClicked(true);
          setCompleted(
            [
              EODStage.BucketTotals,
              EODStage.QuickSales,
              EODStage.Takings,
              EODStage.StockCheck,
            ]
          );
          
          if (response.data.expected_figures.banked_cash) {
            setCompleted(EODStage.Banked);
          }
          
        } else if (response.data.status === 'invalid' || response.data.status === 'missing') {
          const dummyLines = expected_figures.lines.map((line) => {
            if (line.type === 'stock') {
              return {
                ...line,
                quantity: 0
              };
            } else {
              return line;
            }
          });

          setContinueClicked(false);
          setHasSubmitted(false);
          setBanking({ banked_cash: 0, banking_note: "" });
          setLines(dummyLines);
          clearCompleted();
        }
      }).catch((error) => {
        console.log(error);
      });
  }

  const handleRedo = () => {
    clearCompleted();
    setHasSubmitted(false);
    setShowBackdrop(false);
    setContinueClicked(false);

    setTakingsNote("");
    setStockNote("");
    setFigures({});
  }

  useEffect(() => {
    getDateRange();

    if (selectedDate instanceof Date && !isNaN(selectedDate)) {
      getDailyFigures(selectedDate.toLocaleDateString('en-CA'));
    } else {
      console.log('something has gone terribly wrong');
    }
  }, [selectedDate]);

  return (
    <>
      <Helmet>
        <title>End Of Day | Xmas Cookies</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <Card>
                <CardContent>
                  <div style={{ width: '100%', textAlign: 'left' }}>
                    <FormControl className={formClasses.root}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid item
                          lg={12}
                          md={12}
                          xl={12}
                          xs={12}
                          container>
                          <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="date-picker-inline"
                            value={selectedDate}
                            autoOk={true}
                            disableFuture={true}
                            minDate="2021-07-22T21:11:54"
                            onChange={handleDateChange}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                            renderDay={(day, _selectedDate, _isInCurrentMonth, dayComponent) => {
                              return <div style={(dateRange[day.toLocaleDateString('en-CA')] && dateRange[day.toLocaleDateString('en-CA')] !== 'completed')
                                ? { backgroundColor: 'pink' }
                                : { backgroundColor: '' }}>
                                {dayComponent}
                              </div>
                            }}
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>
                    </FormControl>
                    <FormControl className={formClasses.root}>
                      <Button variant="contained" color="primary" onClick={handleRedo}>
                        Redo
                      </Button>
                    </FormControl>
                    <FormControl className={formClasses.root}>
                      <Button variant="outlined" color="primary" onClick={() => { history.push("/dashboard") }}>
                        Dashboard
                      </Button>
                    </FormControl>
                  </div>
                    <div>
                      <BucketTotals
                        lines={((submissionStatus === 'completed' && continueClicked) ? dailyFigures : expectedFigures).lines || []}
                        expanded={activePanel === EODStage.BucketTotals}
                        onExpand={() => {
                          setActivePanel(EODStage.BucketTotals)
                        }}
                        error={hasSubmitted && !isCompleted(EODStage.BucketTotals)}
                        isComplete={isCompleted(EODStage.BucketTotals)}
                        onConfirm={() => {
                          setCompleted(EODStage.BucketTotals);
                          setActivePanel(EODStage.QuickSales);
                        }}
                      />
                      <QuickSales
                        lines={expectedFigures.lines || []}
                        expanded={activePanel === EODStage.QuickSales}
                        onExpand={() => {
                          setActivePanel(EODStage.QuickSales)
                        }}
                        error={hasSubmitted && !isCompleted(EODStage.QuickSales)}
                        isComplete={isCompleted(EODStage.QuickSales)}
                        onConfirm={() => {
                          setCompleted(EODStage.QuickSales);
                          setActivePanel(EODStage.Takings);
                        }}
                      />
                      <Takings
                        dailyFigures={dailyFigures}
                        expectedFigures={expectedFigures}
                        expanded={activePanel === EODStage.Takings}
                        onExpand={() => {
                          setActivePanel(EODStage.Takings)
                        }}
                        error={hasSubmitted && !isCompleted(EODStage.Takings)}
                        isComplete={isCompleted(EODStage.Takings)}
                        onConfirm={({takings_note, received_cash, received_eftpos}) => {
                          setActualTakings(received_cash, received_eftpos);
                          setTakingsNote(takings_note);
			                    setTakingsError(!!takings_note);
                          setCompleted(EODStage.Takings);

                          setActivePanel(EODStage.StockCheck);
                        }}
                      />
                      <StockCheck
                        stockFigures={dailyFigures.lines.filter((line) => line.type === 'stock')}
                        dailyFigures={dailyFigures}
                        expectedFigures={expectedFigures.lines.filter((line) => line.type === 'stock')}
                        expanded={activePanel === EODStage.StockCheck}
                        onExpand={() => {
                          setActivePanel(EODStage.StockCheck)
                        }}
                        error={hasSubmitted && !isCompleted(EODStage.StockCheck)}
                        isComplete={isCompleted(EODStage.StockCheck)}
                        onQuantityChange={
                          (sku, quantity) => {
                            let tmpLines = [...dailyFigures.lines];
                            const index = tmpLines.findIndex((line) => line.type === 'stock' && line.sku_code === sku);
                            tmpLines[index].quantity = quantity;

                            setLines(tmpLines);
                          }
                        }
                        onConfirm={
                          (note) => {
                            setStockNote(note);

                            if (note) {
                              const biglyWrong = dailyFigures.lines.reduce((accumulator, row) => {
                                if (row.type !== "stock") {
                                  return accumulator;
                                }

                                const actual = expectedFigures.lines.find((stockRow) => stockRow.type === 'stock' && stockRow.sku_code === row.sku_code);
                                return accumulator || Math.abs(actual.quantity - row.quantity) >= 12;
                              }, false);

                              setStockError(biglyWrong ? StockWrongness.Bigly : StockWrongness.Smally);
                            } else {
                              setStockError(false);
                            }

                            setCompleted(EODStage.StockCheck);

                            setActivePanel(EODStage.Banked);
                          }
                        }
                    />
                    <Banked
                      expanded={activePanel === EODStage.Banked}
                      onExpand={() => {
                        setActivePanel(EODStage.Banked)
                      }}
                      isComplete={isCompleted(EODStage.Banked)}
                      onConfirm={(value) => {
                        setBanking(value);
                        setCompleted(EODStage.Banked);
                      }}
                    />
                  </div>
                  <div style={{ marginTop: '12px' }}>
                    <Button disabled={continueClicked} onClick={handleSubmit} variant="contained" color="primary">
                      Continue
                    </Button>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <SurveyDialog
        open={showSurveyDialog}
        onSubmit={handleSurveySave}
        onCancel={handleSurveyDialogClose}
      />

      <MessageDialog title="Submitted successfully" onOk={handleFinished} open={showCompletionDialog}>
        <Typography>
          You'll now return to the dashboard.
        </Typography>
      </MessageDialog>

      <MessageDialog title={errorDetails && errorDetails.title} onOk={() => showError(null)} open={!!errorDetails}>
        <Typography>
          {errorDetails && errorDetails.message}
        </Typography>
      </MessageDialog>
      
      <StyledBackdrop open={showBackdrop} />
    </>
  );
};

export default EndOfDay;
