/**
 * Converts a `Date` to a human-readable format, with sensible
 * defaults according to the specified locale.
 *
 * @param{string} locale  - The locale to use to format the date. If
 *                          this is unspecified, en-NZ is used as the
 *                          locale.
 *
 * @returns{string} A formatted date string
 */
export function formatReadable(date, locale) {
  const FORMAT_OPTIONS = {
		weekday: 'short',
		day: 'numeric',
		month: 'short',
		year: 'numeric',
		hour: '2-digit',
		minute: 'numeric',
	};

	return date.toLocaleString(locale !== undefined ? locale : "en-NZ", FORMAT_OPTIONS);
}

/**
 * Converts a date to a string in the form YYYY-MM-DD
 *
 * @param{Date}     - Date to format
 * @returns{string} - Date string formatted as YYYY-MM-DD 
 */
export function formatYMD(date) {
  return date.toISOString().split('T')[0];
}

/**
 * Converts a date to a string in the form DD/MM/YYYY
 *
 * @param{Date}     - Date object
 * @returns{string} - Date string formatted as DD/MM/YYYY
 */
export function formatDMY(date) {
  return formatYMD(date).split('-').reverse().join('/');
}

/**
 * Returns the campaign start date
 *
 * @returns{Date} - Campaign start date
 */
export function getCampaignStartDate() {
  return new Date(localStorage.getItem("campaignStart"));
}

/**
 * Returns the campaign end date
 *
 * @returns{Date} - Campaign end date
 */
export function getCampaignEndDate() {
  return new Date(localStorage.getItem("campaignEnd"));
}

/**
 * Represents the range of dates for which a report should be
 * generated.
 *
 *  - Today :: Today's date is used as the start and end date
 *  - Yesterday :: Yesterday's date is used as the start and end date
 *  - ToDate :: The beginning of the campaign is used as the start
 *              date, and today's date is used as the end date
 *  - Campaign :: The beginning and end dates of the campaign are used
 *                as the start and end dates
 */
export const ReportRange = {
  Today: Symbol('today'),
  Yesterday: Symbol('yesterday'),
  ToDate: Symbol('to-date'),
  Campaign: Symbol('campaign'),
};

/**
 * Used to convert a `ReportRange` value into concrete dates.
 *
 * @param{Symbol} range : A value from `ReportRange`.
 *
 * @returns{Object} An object with keys 'start' and 'end', populated
 *                  with Date objects referring to the start and end
 *                  dates specified by `range`.
 */
export function getRangeDates(range) {
  switch (range) {
    case ReportRange.Today: {
      return {
        'start': new Date(),
        'end': new Date()
      };
    }

    case ReportRange.Yesterday: {
      const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
      return {
        'start': yesterday,
        'end': yesterday
      };
    }

    case ReportRange.ToDate: {
      return {
        'start': getCampaignStartDate(),
        'end': new Date()
      };
    }

    case ReportRange.Campaign: {
      return {
        'start': getCampaignStartDate(),
        'end': getCampaignEndDate()
      };
    }
  }
}
