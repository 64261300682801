import { InputAdornment, TextField } from "@mui/material";
import { useState } from "react";

const CurrencyInput = (props) => {
  const [textValue, setTextValue] = useState("0");

  const handleChange = (text) => {
    setTextValue(text);
  }

  const handleBlur = (text) => {
    const numeric = Number(text) || 0;
    const newText = numeric.toFixed(2);

    setTextValue(newText);

    if (props.onUpdate) {
      props.onUpdate(Number(newText));
    }
  }

  return (
    <TextField
      value={props.disabled ? props.value : textValue}
      onChange={(event) => {
        handleChange(event.target.value);
      }}
      onBlur={(event) => {
        handleBlur(event.target.value);
      }}
      InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}}
      disabled={props.disabled}
      label={props.label}
    />
  );
};

export default CurrencyInput;
