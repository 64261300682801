import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { Typography, FormControl } from '@mui/material';
import { useState } from 'react';

const DateRangePicker = (props) => {
  const [dates, setDates] = useState({
    'start': props.startDate,
    'end': props.endDate
  });

  const handleDatesChanged = (dates) => {
    if (props.onChange) {
      props.onChange(dates);
    }
  }

  const handleStartDateChanged = (date) => {
    setDates((d) => {
      const tmpDates = {
        ...d,
        'start': date,
      };

      if (date > d.end) {
        alert('Start date cannot be after the end date');
        return d;
      }

      handleDatesChanged(tmpDates);
      return tmpDates;
    });
  };

  const handleEndDateChanged = (date) => {
    setDates((d) => {
      const tmpDates = {
        ...d,
        'end': date
      };

      if (date < d.start) {
        alert('End date cannot be before the start date');
        return d;
      }

      handleDatesChanged(tmpDates);
      return tmpDates;
    });
  }

  return (
    <Typography style={{ color: "#666666" }}
                color="textPrimary"
                variant="body1">
      <FormControl>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            id="date-picker-inline"
            label={props.startLabel || 'First date'}
            autoOk={true}
            minDate={props.minDate}
            maxDate={props.maxDate}
            value={props.startDate}
            onChange={handleStartDateChanged}
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
          />
        </MuiPickersUtilsProvider>
      </FormControl>
      <FormControl>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            id="date-picker-inline"
            label={props.endLabel || 'Last date'}
            autoOk={true}
            minDate={props.minDate}
            maxDate={props.maxDate}
            value={props.endDate}
            onChange={handleEndDateChanged}
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
          />
        </MuiPickersUtilsProvider>
      </FormControl>
    </Typography>
  );
};

export default DateRangePicker;
