import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Typography, Backdrop, CircularProgress, InputAdornment } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { DataGrid } from '@material-ui/data-grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import axios from "axios";
import CheckIcon from '@material-ui/icons/Check';
import {formatReadable} from '../Date';
import { Helmet } from 'react-helmet';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    'aria-controls': `nav-tabpanel-${index}`,
  };
}

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  container: {
    maxHeight: 960,
  }, backdrop: {
    zIndex: theme.zIndex.drawer - 2,
    color: '#fff',
  },
}));

export default function Transfers() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [dialOpen, setDialOpen] = React.useState('');
  const [tranOdrs, setTranOdrs] = React.useState([]);
  const [orderDtl, setOderDtl] = React.useState({});
  const [batchNo, setBatchNo] = React.useState([]);

  const handleTranSent = (tranSts, tranId) => {
    if (tranSts) {
      axios.put('/stocktransfers/id/' + tranId + '?key=' + localStorage.getItem("apikey"), JSON.stringify({ "action": "send" }), {
        headers: { 'Content-Type': 'application/json' }
      })
        .then((response) => {
          getTranOdrs();
        })
        .catch((error) => {
        })
    }
  }

  const handlePdfDownload = (tranId) => {
    setOderDtl(orderDtl => {
      const tmpOrdDtl = orderDtl;
      tmpOrdDtl.status = 'Ready to Send';
      return { ...tmpOrdDtl };
    });
    getTranOdrs();
    window.open(process.env.REACT_APP_API_DOMAIN + '/stocktransfers/download/id/' + tranId + '.pdf?key=' + localStorage.getItem("apikey"));
    // axios.get('/stocktransfers/download/id/' + tranId + '.pdf?key=' + localStorage.getItem("apikey"), {
    //   headers: { 'CT-DeviceID': 'skjghsjdkg' }
    // })
    //   .then((response) => {
    //     console.log(response)
    //     getTranOdrs();
    //   })
  }

  const handleOdrReceive = (tranId) => {
    setOderDtl(orderDtl => {
      const tmpOrdDtl = orderDtl;
      tmpOrdDtl.status = 'Received - Cartons Unscanned';
      return { ...tmpOrdDtl };
    });
    axios.put('/stocktransfers/id/' + tranId + '?key=' + localStorage.getItem("apikey"), JSON.stringify({ "action": "receive" }), {
      headers: { 'Content-Type': 'application/json' }
    })
      .then((response) => {
        getTranOdrs();
      })
      .catch((error) => {
      })
  }

  const handleUpdateBatch = (batchId) => {
    const tmpBatchNum = batchNo[batchId];
    setBatchNo([]);
    axios.put('/stocktransfercartons/id/' + batchId + '?key=' + localStorage.getItem("apikey"), JSON.stringify({ "batch_number": tmpBatchNum }), {
      headers: { 'Content-Type': 'application/json' }
    })
      .then((response) => {
        getTranOdrs();
      })
      .catch((error) => {
        setBackDropSts(false);
      })
  }
  const handleClickDialOpen = (val) => {
    setOderDtl(val.row);
    val.row.is_sender ? setDialOpen('sender') : setDialOpen('receiver')
  };
  const handleClose = () => {
    setDialOpen('');
  };
  const handleBatchChange = (cartonId, batchNo) => {
    //update batch id
    setBatchNo({ [cartonId]: batchNo })
  }
  const getTranOdrs = () => {
    setBackDropSts(true);
    axios.get('/stocktransfers?key=' + localStorage.getItem("apikey"))
      .then((response) => {
        console.log(response);
        setTranOdrs(response.data.results);
        if (Object.keys(orderDtl).length !== 0) {
          setOderDtl(response.data.results[Object.keys(response.data.results).find(key => (response.data.results[key]['id'] === orderDtl['id']))]);
        }
        setBackDropSts(false);
      })
  }
  const [backDropSts, setBackDropSts] = React.useState(false);
  const tranOdrsCol = [
    {
      field: 'reference_number',
      headerName: 'Transfer#',
      headerClassName: 'super-app-theme--header',
      flex: 1,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'sender',
      headerName: 'Stock Sender',
      headerClassName: 'super-app-theme--header',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (para) => (
        para.row.is_sender ? 'You' : para.value.first_name + ' ' + para.value.last_name
      ),
    },
    {
      field: 'receiver',
      headerName: 'Stock Receiver',
      headerClassName: 'super-app-theme--header',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (para) => (
        para.row.is_receiver ? 'You' : para.value.first_name + ' ' + para.value.last_name
      ),
    },
    {
      field: 'delivery_method',
      headerName: 'Method',
      headerClassName: 'super-app-theme--header',
      type: 'number',
      flex: 1,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'stock_sent_at',
      headerName: 'Dispatch Date',
      headerClassName: 'super-app-theme--header',
      type: 'number',
      flex: 1,
      headerAlign: 'center',
			align: 'center',
			renderCell: (param) => formatReadable(new Date(param.row.stock_sent_at))
    },
    {
      field: 'cartons',
      headerName: 'Cartons',
      headerClassName: 'super-app-theme--header',
      sortable: false,
      type: 'number',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (para) => (
        para.value.length
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      headerClassName: 'super-app-theme--header',
      sortable: false,
      type: 'number',
      flex: 1,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'Operate',
      headerName: 'Operate',
      headerClassName: 'super-app-theme--header',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: (para) => (
        <div><Button variant="outlined" size="small" color="primary" onClick={() => handleClickDialOpen(para)}>
          Open
        </Button></div>
      ),
    }
  ];

  useEffect(() => {
    getTranOdrs();
  }, []);

  return (
    <>
      <Helmet>
        <title>Transfers | Xmas Cookies</title>
      </Helmet>
      <div className={classes.root}>
        <AppBar position="static" color="default">
          <Tabs
            variant="fullWidth"
            value={value}
            onChange={handleChange}
            aria-label="nav tabs example"
            indicatorColor="primary"
            textColor="primary"
          >
            <LinkTab label="Active Transfers" href="/drafts" {...a11yProps(0)} />
            <LinkTab label="Transfers History" href="/trash" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <div style={{ height: 800, width: '100%' }}>
            <div style={{ display: 'flex', height: '100%' }}>
              <div style={{ flexGrow: 1 }} className={classes.root}>

                <DataGrid
                  rows={tranOdrs.filter(row => (row.status !== 'Complete'))}
                  columns={tranOdrsCol}
                  pageSize={100}
                  disableSelectionOnClick={true}

                />
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div style={{ height: 800, width: '100%' }}>
            <div style={{ display: 'flex', height: '100%' }}>
              <div style={{ flexGrow: 1 }} className={classes.root}>

                <DataGrid
                  rows={tranOdrs.filter(row => (row.status === 'Complete'))}
                  columns={tranOdrsCol}
                  pageSize={100}
                  disableSelectionOnClick={true}

                />
              </div>
            </div>
          </div>
        </TabPanel>
        <Dialog open={Boolean(dialOpen === 'sender')} onClose={handleClose} maxWidth='md' aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Transfer # {orderDtl.hasOwnProperty('id') && orderDtl.reference_number}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Summary: You're transferring {orderDtl.hasOwnProperty('id') && orderDtl.cartons.length} cartons to {orderDtl.hasOwnProperty('id') && orderDtl.receiver.first_name} {orderDtl.hasOwnProperty('id') && orderDtl.receiver.last_name}.
            </DialogContentText>
            <Typography variant="h6" gutterBottom>
              Instructions:
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
              1. Select cartons from your stock and enter Batch numbers below.
              Batch can found on each carton. It has "MO" at the start, then 6 digits.<br />
              2. Download PDF paperwork below and print it ({orderDtl.hasOwnProperty('id') && orderDtl.cartons.length}x A4 sheets).<br />
              3. Affix each printed sheet to the correct carton — match by Batch.<br />
              4. Contact the receiving seller and arrange meeting to transfer stock.<br />
              5. Once the cartons have left your possession, tick the box below.<br />
            </Typography>
            <Typography variant="h6" gutterBottom>
              Important:
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
              If you do not have sufficient stock to fulfil this transfer, contact HQ immediately.
            </Typography>
            <Typography style={{ fontWeight: 'bold' }} align="center" variant="caption" display="block" gutterBottom>
              Please select the following cartons for transfer:
            </Typography>
            <TableContainer component={Paper} style={{ maxHeight: 400 }}>
              <Table stickyHeader className={classes.table} aria-label="simple table">
                <TableBody>
                  {orderDtl.hasOwnProperty('id') && orderDtl.cartons.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell component="th" scope="row">
                        1X
                      </TableCell>
                      <TableCell>{row.sku_code}</TableCell>
                      <TableCell>{row.status}</TableCell>
                      <TableCell><TextField
                        id="outlined-multiline-static"
                        value={row.batch_number}
                        onChange={(eve) => { handleBatchChange(row.id, eve.target.value) }}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">MO</InputAdornment>,
                        }}
                      /></TableCell>
                      <TableCell>{row.batch_number ? <CheckIcon /> : <Button disabled={!batchNo[row.id]} variant="outlined" onClick={() => { handleUpdateBatch(row.id) }}>Update</Button>}</TableCell></TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <FormControlLabel
              control={<Checkbox name="antoine" checked={orderDtl.status === 'Sent' || orderDtl.status === 'Received - Cartons Unscanned'} disabled={orderDtl.status !== 'Ready to Send'} onChange={(eve) => { handleTranSent(eve.target.checked, orderDtl.id) }} />}
              label="Transfer complete (stock has left me)."
            />
          </DialogContent>
          <DialogActions>
            <Button disabled={orderDtl.status === 'Pending'} onClick={() => { handlePdfDownload(orderDtl.id) }} color="primary" variant="contained">
              Download PDF
            </Button>
            <Button onClick={handleClose} color="primary" variant="outlined">
              close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={Boolean(dialOpen === 'receiver')} onClose={handleClose} maxWidth='md' aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Transfer # {orderDtl.hasOwnProperty('id') && orderDtl.reference_number}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Summary: You're receiving {orderDtl.hasOwnProperty('id') && orderDtl.cartons.length} cartons from {orderDtl.hasOwnProperty('id') && orderDtl.sender.first_name} {orderDtl.hasOwnProperty('id') && orderDtl.sender.last_name}.
            </DialogContentText>
            <Typography variant="h6" gutterBottom>
              Instructions:
            </Typography>
            {(orderDtl.status === 'Pending' || orderDtl.status === 'Please Print PDF' || orderDtl.status === 'Ready to Send' || orderDtl.status === 'Sent') && <Typography variant="caption" display="block" gutterBottom>
              The seller has not yet marked this shipment as Sent. Once they have, please come back to this screen to receive it.
            </Typography>}
            {(orderDtl.status === 'Received - Cartons Unscanned') && <Typography variant="caption" display="block" gutterBottom>
              1. Arrange to receive {orderDtl.hasOwnProperty('id') && orderDtl.cartons.length} cartons from the sending seller.<br />
              2. Click the Receive button below.<br />
              3.  Using your device scan in the {orderDtl.hasOwnProperty('id') && orderDtl.cartons.length} QR codes, one one each carton..<br />
              4. Refresh this screen.<br />
            </Typography>}
            <Typography variant="h6" gutterBottom>
              Important:
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
              If you have not received all {orderDtl.hasOwnProperty('id') && orderDtl.cartons.length} cartons, please contact HQ immediately.
            </Typography>
            {orderDtl.status === 'Complete' && <Typography variant="caption" display="block" gutterBottom>You have scanned in all the cartons, and this transfer is complete.</Typography>}
            <TableContainer component={Paper} style={{ maxHeight: 400 }}>
              <Table stickyHeader className={classes.table} aria-label="simple table">
                <TableBody>
                  {orderDtl.hasOwnProperty('id') && orderDtl.cartons.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell component="th" scope="row">
                        1X
                      </TableCell>
                      <TableCell>{row.sku_code}</TableCell>
                      <TableCell>MO{row.batch_number}</TableCell>
                      <TableCell>{row.status}</TableCell>
                      {/* <TableCell>{orderDtl.status === 'Complete' ? <Button disabled={!batchNo[row.id]} variant="outlined" onClick={() => { handleUpdateBatch(row.id) }}>Claim</Button> : ''}</TableCell> */}
                      <TableCell>{orderDtl.status === 'Complete' ? <Button disabled={!batchNo[row.id]} variant="outlined" onClick={() => { alert('If this Stock Transfer has damaged or missing items, please contact HQ to enter your claim.') }}>Claim</Button> : ''}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <FormControlLabel
              control={<Checkbox name="antoine" disabled={orderDtl.status === 'Pending' || orderDtl.status === 'Please Print PDF' || orderDtl.status === 'Ready to Send' || orderDtl.status === 'Sent'} />}
              label="Complete"
            />
          </DialogContent>
          <DialogActions>
            <Button disabled={orderDtl.status === 'Pending' || orderDtl.status === 'Please Print PDF' || orderDtl.status === 'Ready to Send'} onClick={() => { handleOdrReceive(orderDtl.id) }} color="primary" variant="contained">
              Receive
            </Button>
            <Button onClick={handleClose} color="primary" variant="outlined">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Backdrop className={classes.backdrop} open={backDropSts}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    </>
  );
}
