import { Paper, Typography, Table, TableHead, TableCell, TableBody, TableRow } from '@mui/material';
import { useContext } from 'react';
import { SKUInfo } from '../SKUInfo';

export const SKUListStyle = {
  'tr:nth-child(even)': {
    td: {
      background: 'rgb(0, 0, 0, 0.03)'
    }
  },

  'th': {
    fontWeight: 'bold'
  }
};

const SKUList = (props) => {
  const skuInfo = useContext(SKUInfo);

  const lines = props.lines.sort((a, b) => skuInfo.sorting[a.sku_code] > skuInfo.sorting[b.sku_code] ? 1 : -1);
  
  return (
    <Paper variant="outlined" style={{ padding: '16px', margin: '8px' }} sx={props.sx}>
      {
        props.label && (
          <Typography style={{ fontWeight: 'bold', marginBottom: '12px' }}>
            {props.label}
          </Typography>
        ) || null
      }
      <Table size="small" sx={SKUListStyle}>
        <TableHead>
          <TableRow>
            <TableCell>
              SKU
            </TableCell>
            <TableCell>
              Quantity
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            lines.map((line, index) => {
              return (
                <TableRow className={`sku-${line.sku_code.toUpperCase()}`} key={index}>
                  <TableCell>
                    {line.sku_code}
                  </TableCell>
                  <TableCell>
                    {line.quantity}
                  </TableCell>
                </TableRow>
              );
            })
          }
        </TableBody>
      </Table>
    </Paper>
  );
}

export default SKUList;
