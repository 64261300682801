import { useState } from "react";
import BankDialog from "../BankDialog"
import Section from "../Section"

const Banked = (props) => {
  const [showDialog, setShowDialog] = useState(false);

  const handleConfirm = (bankedCash, bankNote) => {
    if (!bankNote) {
      return;
    }
    
    if (props.onConfirm) {
      props.onConfirm({ banked_cash: Math.floor(bankedCash * 100.0), banking_note: bankNote });
    }

    setShowDialog(false);
  }

  return (
    <>
      <Section
        title="Banked Today?"
        checkLabel="Please tick if you banked today."
        onConfirm={() => {
          setShowDialog(true);
        }}
        isComplete={props.isComplete}
        expanded={props.expanded}
        onExpand={props.onExpand}
      />
      <BankDialog
        open={showDialog}
        onClose={() => {
          setShowDialog(false);
        }}
        onConfirm={handleConfirm}
      />
    </>
  );
};

export default Banked;
