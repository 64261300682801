import React, { useEffect } from 'react';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography
} from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';import { green, red } from '@material-ui/core/colors';

const Budget = (props) => {
  return <Card
    style={{ height: '100%' }}
    {...props}
  >
    <CardContent>
      <Grid
        container
        spacing={3}
        style={{ justifyContent: 'space-between' }}
      >
        <Grid item >
          <Typography
            color="textSecondary"
            gutterBottom
            variant="h6"
          >
            Buckets Delivered
          </Typography>
          <Typography
            color="textPrimary"
            variant="h3"
          >
            {props.dashdata ? props.dashdata['total_deliveries'] : 0}
          </Typography>
        </Grid>
        <Grid item>
          <Avatar
            style={{
              backgroundColor: green[300],
              height: 56,
              width: 56
            }}
          >
            <LocalShippingIcon />
          </Avatar>
        </Grid>
      </Grid>
      <Box
        style={{
          pt: 2,
          display: 'flex',
          alignItems: 'center'
        }}
      >
        
        <Typography 
          color="textSecondary"
          variant="caption"
        >
          Buckets Delivered
        </Typography>
      </Box>
    </CardContent>
  </Card>
};

export default Budget;
