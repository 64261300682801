import React, { useEffect } from 'react';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography
} from '@material-ui/core';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import { green, red } from '@material-ui/core/colors';

const Budget = (props) => {
  return <Card
    style={{ height: '100%' }}
    {...props}
  >
    <CardContent>
      <Grid
        container
        spacing={3}
        style={{ justifyContent: 'space-between' }}
      >
        <Grid item >
          <Typography
            color="textSecondary"
            gutterBottom
            variant="h6"
          >
            Buckets Per Org.
          </Typography>
          <Typography
            color="textPrimary"
            variant="h3"
          >
            {props.dashdata ? props.dashdata['kpi_orders_per_organisation'] : 0}
          </Typography>
        </Grid>
        <Grid item>
          <Avatar
            style={{
              backgroundColor: green[900],
              height: 56,
              width: 56
            }}
          >
            <GroupWorkIcon />
          </Avatar>
        </Grid>
      </Grid>
      <Box
        style={{
          pt: 2,
          display: 'flex',
          alignItems: 'center'
        }}
      >
        
        <Typography 
          color="textSecondary"
          variant="caption"
        >
          Buckets Delivered
        </Typography>
      </Box>
    </CardContent>
  </Card>
};

export default Budget;
