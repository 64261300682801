import { useContext, useState } from 'react';
import { useHistory } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";
import { DataGrid } from '@mui/x-data-grid';
import { makeStyles } from '@material-ui/styles';
import Report from '../components/Report';
import { SKUInfo } from '../components/SKUInfo';
import { StyledBackdrop } from '../components/StyledBackdrop';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .super-app-theme--header': {
      backgroundColor: '#f0f0f0',
      fontWeight: 'bold',
      fontSize: '10px'
    },
  }, backdrop: {
    zIndex: theme.zIndex.drawer - 2,
    color: '#fff',
  },
}));

const QuickSalesReport = () => {
  let history = useHistory();
  const classes = useStyles();

  const skuInfo = useContext(SKUInfo);

  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);

  const [reportDownload, setReportDownload] = useState('');

  const [showBackdrop, setShowBackdrop] = useState(false);

  const handleReportRun = (dates) => {
    setShowBackdrop(true);

    axios.post(
      `/reports/run/quicksales_report?key=${localStorage.getItem("apikey")}`,
      {
        "date_range": dates.start.toLocaleDateString('en-CA') + "," + dates.end.toLocaleDateString('en-CA')
      },
      {
        headers: { 'content-type': 'application/json' }
      }).then((response) => {
        let tmpCol = [];
        for (var colKey in response.data.headers) {
          tmpCol.push({
            field: colKey,
            headerName: response.data.headers[colKey],
            headerClassName: `super-app-theme--header sku-header-${colKey.toUpperCase()}`,
            cellClassName: `sku-${colKey.toUpperCase()}`,
            renderCell: colKey == 'average_order'
              ? (param) => (Math.round(param.row.average_order * 100.0) / 100.0).toFixed(2) // Round to 2dp
              : undefined,
            headerAlign: 'center',
            minWidth: 200,
          })
        }
        let tmpRow = response.data.rows;
        for (var rowKey in tmpRow) {
          tmpRow[rowKey].id = parseInt(rowKey)
        }
        setRows(tmpRow);
        setColumns(tmpCol);
        setReportDownload(response.data.links.csv);
        setShowBackdrop(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <>
      <Helmet>
        <title>Quick Sales | Xmas Cookies</title>
      </Helmet>
      <Report onRun={handleReportRun}
        downloadURL={reportDownload}>
        <div style={{ overflowX: 'scroll', height: 800 }} className={classes.root}>
          <DataGrid
            sx={skuInfo.classes}
            rows={rows}
            columns={columns}
            pageSize={100}
            disableSelectionOnClick={true}
            onRowClick={(GridRowParams, event) => {
              console.log(GridRowParams);
              history.push("/tasks/revieworders/" + GridRowParams.id)
            }}
          />
        </div>
      </Report>
      <StyledBackdrop open={showBackdrop} />
    </>
  );
};

export default QuickSalesReport;
