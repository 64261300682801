import { Grid } from '@mui/material';
import {Children} from 'react';
import ReportControls from './ReportControls';
import {Container, Card, CardContent} from '@mui/material';
import { SKUInfoProvider } from './SKUInfo';

const Report = (props) => {
  const handleReportRun = (dates) => {
    if (dates.start == null || dates.end == null) {
      return;
    }
    
    if (props.onRun) {
      props.onRun(dates);
    }
  };

  return (
    <SKUInfoProvider>
      <Container style={{maxWidth: '95vw'}}>
        <Grid container spacing={3}>
          <Grid item
            lg={12}
            md={12}
            xl={12}
            xs={12}>
            <Card>
              <CardContent>
                <ReportControls
                  disableDateControl={props.disableDateControl}
                  onRun={handleReportRun}
                  downloadURL={props.downloadURL} />
              </CardContent>
            </Card>
          </Grid>
          {
            Children.map(props.children, (child, index) => {
              return (
                <Grid item lg={12} md={12} xl={12} xs={12} key={index}>
                  <Card>
                    <CardContent>
                      {child}
                    </CardContent>
                  </Card>
                </Grid>
              );
            })
          }
        </Grid>
      </Container>
    </SKUInfoProvider>
  )
};

export default Report;
