import { useState } from 'react';
import { Backdrop, CircularProgress } from '@material-ui/core';
import axios from "axios";
import { Helmet } from "react-helmet";
import { DataGrid } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/styles';
import Report from '../components/Report';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .super-app-theme--header': {
      backgroundColor: '#f0f0f0',
      fontWeight: 'bold',
      fontSize: '10px'
    },
  }, backdrop: {
    zIndex: theme.zIndex.drawer - 2,
    color: '#fff',
  },
}));

const ScanBackReport = () => {
  const classes = useStyles();

  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);

  const [reportDownload, setReportDownload] = useState('');

  const [showBackdrop, setShowBackdrop] = useState(false);

  const handleReportRun = (dates) => {
    setShowBackdrop(true);

    axios.post(
      `/reports/run/scanback_report?key=${localStorage.getItem("apikey")}`,
      {
        "date_range": dates.start.toLocaleDateString('en-CA') + "," + dates.end.toLocaleDateString('en-CA')
      },
      {
        headers: { 'content-type': 'application/json' }
      }).then(function (response) {
        let tmpCol = [];
        for (var colKey in response.data.headers) {
          tmpCol.push({
            field: colKey,
            headerName: response.data.headers[colKey],
            headerClassName: 'super-app-theme--header',
            flex: 1,
            headerAlign: 'center',
            renderCell: (params) => {
              if (params.value === true) {
                return 'yes'
              } else if (params.value === false) {
                return 'no'
              } else {
                return params.value
              }
            },
          })
        }
        let tmpRow = response.data.rows;
        for (var rowKey in tmpRow) {
          tmpRow[rowKey].id = parseInt(rowKey)
        }
        setRows(tmpRow);
        setColumns(tmpCol);
        setReportDownload(response.data.links.csv);
        setShowBackdrop(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <>
      <Helmet>
        <title>Scanbacks | Xmas Cookies</title>
      </Helmet>
      <Report onRun={handleReportRun}
              downloadURL={reportDownload}
              disableDateControl={true}>
        <div style={{ overflow: 'auto', height: 800 }}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={100}
            disableSelectionOnClick={true} />
        </div>
      </Report>
      <Backdrop className={classes.backdrop} open={showBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default ScanBackReport;
