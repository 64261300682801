import React, { useContext, useEffect } from 'react';
import {
  Box,
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  IconButton,
  FormControl,
  Button,
  FormControlLabel,
  Checkbox
} from '@mui/material';

import { DataGrid } from '@mui/x-data-grid';

import {
  useHistory,
} from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";

import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { makeStyles } from '@material-ui/styles';

import { Event, Visibility, CallSplit } from '@mui/icons-material';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { StyledBackdrop } from '../components/StyledBackdrop';
import { SKUInfo } from '../components/SKUInfo';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .super-app-theme--header': {
      backgroundColor: '#f0f0f0',
      fontWeight: 'bold',
    },
  }
}));

const ReviewDeliveries = () => {
  let history = useHistory();
  const classes = useStyles();

  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [rows, setRows] = React.useState([]);
  const [eveId, setEveId] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [orgOrderRows, setOrgOrderRows] = React.useState([]);

  const [devDate, setDevDate] = React.useState(new Date());
  const [sepOdrId, setSepOdrId] = React.useState([]);
  const [backDropSts, setBackDropSts] = React.useState(false);

  const skuInfo = useContext(SKUInfo);

  const columns = [
    {
      field: 'org',
      headerName: 'Organisation',
      headerClassName: 'super-app-theme--header',
      flex: 1,
      headerAlign: 'center'
    },
    {
      field: 'contact',
      headerName: 'Contact',
      headerClassName: 'super-app-theme--header',
      flex: 1,
      headerAlign: 'center'
    },
    {
      field: 'Operate',
      headerName: 'Operate',
      headerClassName: 'super-app-theme--header',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: (params) => (
        <div>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            {/* XXX: This does literally nothing */} 
            <KeyboardDatePicker
              disableToolbar
              open={params.id === eveId}
              autoOk={true}
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id="date-picker-inline"
              label="Delivery run for"
              value={selectedDate}
              onChange={(value) => { console.log(params.id, value); setEveId('') }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              TextFieldComponent={() => (
                <IconButton
                  onClick={() => { setEveId(params.id) }}
                  color="primary"
                  aria-label="Move these orders to another delivery day"
                >
                  <Event />
                </IconButton>
              )}
            />
          </MuiPickersUtilsProvider>
          <IconButton
            color="primary"
            aria-label="View/edit these orders"
            onClick={() => { history.push("/tasks/revieworders/" + params.id) }}>
            <Visibility />
          </IconButton>
          <IconButton
            color="primary"
            aria-label="Split these orders into two delivery days"
            onClick={() => { handleOpen(params.id) }}>
            <CallSplit />
          </IconButton>
        </div>
      ),
    }
  ];

  const handleOpen = (id) => {
    setOpen(true);
    setOrgOrderRows([rows.find(row => row.id === id)]);
    setSepOdrId([]);
    console.log([rows.find(row => row.id === id)]);
  }

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleDevDateChange = (date) => {
    setDevDate(date);
    console.log(date);
  };

  const handleChangeSepOdrId = (idChk, id) => {
    let tmpArrOdr = sepOdrId;
    idChk ? tmpArrOdr.push(id) : tmpArrOdr.splice(tmpArrOdr.indexOf(id), 1)
    setSepOdrId(tmpArrOdr);
    console.log(sepOdrId);
  }

  const handleClose = () => {
    setOpen(false);
  };

  const getOrders = (date) => {
    setBackDropSts(true);
    axios.get('/orders/delivered_on_date/' + date + '?key=' + localStorage.getItem("apikey"))
      .then((response) => {
        const results = response.data.results;
        let orders = [];

        results.forEach(ele => {
          const quantities = skuInfo.info.reduce((accumulator, sku) => {
            const line = Object.keys(ele.lines).find(key => (ele.lines[key]["sku_code"] === sku.code));

            const quantity = line === undefined ? 0 : ele.lines[line]["quantity"];
            return { ...accumulator, [sku.code]: quantity };
          }, {});

          orders.push({
            'id': ele.id,
            'org': ele.customer.organisation.name,
            'contact': ele.customer.name,
            'total': ele.lines.reduce((accumulator, current) => accumulator + current.quantity, 0),
            ...quantities,
          });
        });

        setRows(orders);
        setBackDropSts(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    if (selectedDate instanceof Date && !isNaN(selectedDate)) {
      getOrders(selectedDate.toLocaleDateString('en-CA'));
    } else {
      console.log('bad date');
    }
  }, [selectedDate]);

  return (
    <>
      <Helmet>
        <title>Review Deliveries | Xmas Cookies</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              lg={12}
              md={12}
              xl={12}
              xs={12}
            >
              <Card>
                <CardContent>
                  <Typography
                    style={{ color: '#666666' }}
                    color="textPrimary"
                    variant="body1"
                  >
                    Welcome to the delivery-run planner. By default, the day's deliveries appear in the same sequence that you visited them when taking orders. If you wish to alter the delivery sequence, drag organisations below to sort.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid
              item
              lg={12}
              md={12}
              xl={12}
              xs={12}
            >
              <Card>
                <CardContent>
                  <div style={{ width: '100%', textAlign: 'right' }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid item
                            lg={12}
                            md={12}
                            xl={12}
                            xs={12}
                            container>
                        <KeyboardDatePicker
                          disableToolbar
                          autoOk={true}
                          variant="inline"
                          format="dd/MM/yyyy"
                          margin="normal"
                          id="date-picker-inline"
                          label="Delivery run for"
                          value={selectedDate}
                          onChange={handleDateChange}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </div>
                  <div style={{ overflowX: 'scroll', height: 800 }} className={classes.root}>
                    <DataGrid
                      rows={rows}
                      columns={[...columns, ...skuInfo.columns]}
                      sx={{...skuInfo.classes}}
                      pageSize={100}
                      disableSelectionOnClick={true}
                    />
                  </div>
                </CardContent>
              </Card>
            </Grid></Grid>
        </Container>
      </Box>

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Select Orders to move</DialogTitle>
        <DialogContent>
          <FormControl className={classes.formControl}>
            {orgOrderRows.map((row) => {
              return <FormControlLabel key={row.id}
                control={
                  <Checkbox
                    name={row.id}
                    color="primary"
                    onClick={(eve) => { handleChangeSepOdrId(eve.target.checked, row.id) }}
                  />
                }
                label={row.contact + ' ' + row.total + ' Bukets'}
              />
            })}
          </FormControl>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid item
              lg={12}
              md={12}
              xl={12}
              xs={12}
              container>
              <KeyboardDatePicker
                disableToolbar
                autoOk={true}
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="Delievery"
                value={devDate}
                onChange={handleDevDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </Grid>
          </MuiPickersUtilsProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <StyledBackdrop open={backDropSts} />
    </>
  );
};

export default ReviewDeliveries;
