import { Dialog, DialogTitle, DialogActions, DialogContent, Button } from '@mui/material';

const MessageDialog = (props) => {
  return (
    <Dialog {...props}>
      <DialogContent>
        <DialogTitle>{props.title}</DialogTitle>
        {props.children}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onOk}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
}

export default MessageDialog;
