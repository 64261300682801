import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme,
  colors
} from '@material-ui/core';

import React, { PureComponent } from 'react';
import {
  ComposedChart,
  Line,
  Area,
  Label,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,LabelList
} from 'recharts';

import { red, yellow } from '@material-ui/core/colors';


const Sales = (props) => {
  const theme = useTheme();
  const data = [
    {
      name: 'Current Numbers',
      'My Goal': props.dashdata.targets ? props.dashdata.targets['My Goal'] : 0,
      'Actual Sales': props.dashdata.targets ? props.dashdata['total_sales'] : 0,
    }
  ];


  return (
    <Card {...props}>
      <CardHeader

        title="My Progress"
      />
      <Divider />
      <CardContent>
        <Box
          style={{
            height: 200,
            position: 'relative'
          }}
        >
          <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
              layout="vertical"

              data={data}
              margin={{
                top: 20,
                right: 0,
                bottom: 20,
                left: 0,
              }}
            >
              <CartesianGrid vertical={false} horizontal={false} />
              <XAxis type="number" tick={false} axisLine={false} />
              <YAxis dataKey="name" type="category" hide={true} />
              <Tooltip />
              <Legend formatter={(value) => {
                return <span style={{ color: '#000000' }}>{value}</span>;
              }} />
              <Bar dataKey="My Goal" barSize={20} fill={yellow[600]} label={(event)=>{
              return <text x={event.width/2} y={event.y+event.height/2}>{'My Goal'}</text>}} />
              <Bar dataKey="Actual Sales" barSize={20} fill={yellow[900]} label={(event)=>{
              return <text x={event.width/2} y={event.y+event.height/2}>{props.dashdata.targets ? Math.round(props.dashdata['total_sales'] / props.dashdata.targets['My Goal']*10000)/100 : 0}%</text>}} />

              <ReferenceLine x={0} stroke="black" />

              <ReferenceLine x={props.dashdata.targets ? props.dashdata.targets['Base Target'] : 0} stroke="red" label={<Label value="Base Target" offset={0} position="top" />} />
              <ReferenceLine x={props.dashdata.targets ? props.dashdata.targets['Base Target'] : 0} stroke="red" label={<Label value={props.dashdata.targets ? props.dashdata.targets['Base Target'] : 0} offset={0} position="bottom" />} />

              <ReferenceLine x={props.dashdata.targets ? props.dashdata.targets['Achiever'] : 0} stroke="red" label={<Label value="Achiever" offset={0} position="top" />} />
              <ReferenceLine x={props.dashdata.targets ? props.dashdata.targets['Achiever'] : 0} stroke="red" label={<Label value={props.dashdata.targets ? props.dashdata.targets['Achiever'] : 0} offset={0} position="bottom" />} />

              <ReferenceLine x={props.dashdata.targets ? props.dashdata.targets['High Roller'] : 0} stroke="red" label={<Label value="High Roller" offset={0} position="top" />} />
              <ReferenceLine x={props.dashdata.targets ? props.dashdata.targets['High Roller'] : 0} stroke="red" label={<Label value={props.dashdata.targets ? props.dashdata.targets['High Roller'] : 0} offset={0} position="bottom" />} />

              <ReferenceLine x={props.dashdata.targets ? props.dashdata.targets['Superstar'] : 0} stroke="red" label={<Label value="Superstar" offset={0} position="top" />} />
              <ReferenceLine x={props.dashdata.targets ? props.dashdata.targets['Superstar'] : 0} stroke="red" label={<Label value={props.dashdata.targets ? props.dashdata.targets['Superstar'] : 0} offset={0} position="bottom" />} />
              
              <ReferenceLine x={props.dashdata.targets ? props.dashdata.targets['My Goal'] : 0} stroke="red" label={<Label value="My Goal" offset={0} position="top" />} />
              <ReferenceLine x={props.dashdata.targets ? props.dashdata.targets['My Goal'] : 0} stroke="red" label={<Label value={props.dashdata.targets ? props.dashdata.targets['My Goal'] : 0} offset={0} position="bottom" />} />

            </ComposedChart>
          </ResponsiveContainer>
        </Box>
      </CardContent>
    </Card>
  );
};

export default Sales;
