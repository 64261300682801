import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Stack,
  TextField
} from "@mui/material";
import { useState } from "react";

const StocktakeDialog = (props) => {
  const [note, setNote] = useState("");

  const handleContinue = () => {
    if (props.onConfirm) {
      props.onConfirm(note);
    }
  };

  return (
    <Dialog open={props.open}>
      <DialogTitle>Daily Stock Check</DialogTitle>
      <DialogContent>
        {
          !props.error && (
            <Typography>Congratulations, your stocktake is correct!</Typography>
          ) || (
            <Stack spacing={2}>
              <Typography>
                It looks like you've got the wrong number of cartons.
                Please count again and if you're sure, HQ will call you.
              </Typography>

              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>SKU</TableCell>
                    <TableCell>Cartons (Expected)</TableCell>
                    <TableCell>Units (Expected)</TableCell>
                    <TableCell>Cartons (Actual)</TableCell>
                    <TableCell>Units (Actual)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    props.stock.map(({ ok, sku_code, actualCartons, actualUnits, expectedCartons, expectedUnits }, index) => {
                      const style = !ok ? { background: '#F0C0C8' } : {};
                      return (
                        <TableRow sx={style} key={index}>
                          <TableCell>{sku_code}</TableCell>
                          <TableCell>{expectedCartons}</TableCell>
                          <TableCell>{expectedUnits}</TableCell>
                          <TableCell>{actualCartons}</TableCell>
                          <TableCell>{actualUnits}</TableCell>
                        </TableRow>
                      );
                    })
                  }
                </TableBody>
              </Table>

              <TextField
                multiline
                fullWidth
                value={note}
                rows={4}
                placeholder="If you're sure about your stocktake, please provide an explanation here."
                onChange={(event) => setNote(event.target.value)}
              />
            </Stack>
          )
        }
      </DialogContent>
      <DialogActions>
        {
          !props.error && (
          <Button onClick={handleContinue}>
            Continue
          </Button>
          ) || (
            <>
              <Button onClick={props.onClose}>Cancel</Button>
              <Button onClick={handleContinue} disabled={!note}>Continue</Button>
            </>
          )
        }
      </DialogActions>
    </Dialog>
  );
};

export default StocktakeDialog;
