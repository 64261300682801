import { useState, useEffect, useContext } from 'react';

import {
  Box,
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
} from '@mui/material';

import {
  useHistory,
} from "react-router-dom";

import axios from "axios";
import { Helmet } from "react-helmet";
import { DataGrid } from '@mui/x-data-grid';
import { makeStyles } from '@material-ui/styles';

import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { formatReadable } from '../Date';
import { StyledBackdrop } from '../components/StyledBackdrop';
import { SKUInfo } from '../components/SKUInfo';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const columns = [
  {
    field: 'org',
    headerName: 'Organisation',
    headerClassName: 'super-app-theme--header',
    headerAlign: 'center',
    width: 200
  },
  {
    field: 'contact',
    headerName: 'Contact',
    headerClassName: 'super-app-theme--header',
    headerAlign: 'center',
    width: 200
  },
  {
    field: 'delivery',
    headerName: 'Delivery Date',
    headerClassName: 'super-app-theme--header',
    headerAlign: 'center',
    renderCell: (param) => {
      let prettyDate = "(Invalid date)";
      if (param.row.delivery !== null) {
        const deliveryDate = new Date(Date.parse(param.row.delivery));
        prettyDate = formatReadable(deliveryDate);
      }

      return prettyDate;
    },
    width: 200
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    '& .super-app-theme--header': {
      backgroundColor: '#f0f0f0',
      fontWeight: 'bold',
    },
  }
}));

const ReviewOrders = () => {
  let history = useHistory();
  const classes = useStyles();
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [rows, setRows] = useState([]);

  const skuInfo = useContext(SKUInfo);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const getOrders = (date) => {
    setShowBackdrop(true);

    axios.get(`/orders/ordered_on_date/${date}?key=${localStorage.getItem("apikey")}`)
      .then((response) => {
        setRows(() => {
          let orders = [];

          response.data.results.forEach((ele, index) => {
            const quantities = skuInfo.reduce((accumulator, sku) => {
              const line = Object.keys(ele.lines).find(key => (ele.lines[key]["sku_code"] === sku.code));
              const quantity = line === undefined ? 0 : ele.lines[line]["quantity"];

              return { ...accumulator, [sku.code]: quantity };
            }, {});

            orders.push({
              'id': index,
              'orgId': ele.customer.organisation.id,
              'org': ele.customer.organisation.name,
              'contact': ele.customer.name,
              'delivery': ele.delivered_at,
              ...quantities
            })
          })
          return orders;
        })
        setShowBackdrop(false);
      }).catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    if (selectedDate instanceof Date && !isNaN(selectedDate)) {
      getOrders(selectedDate.toLocaleDateString('en-CA'));
    } else {
      console.log('bad date');
    }
  }, [selectedDate]);

  return (
    <>
      <Helmet>
        <title>Review Orders | Xmas Cookies</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              lg={12}
              md={12}
              xl={12}
              xs={12}
            >
              <Card>
                <CardContent>
                  <Typography
                    style={{ color: '#666666' }}
                    color="textPrimary"
                    variant="body1"
                  >
                    Use this screen to review a day's orders after entry. If you find errors, return to your iPad and correct them. Once you are happy with the orders as displayed here, click the Looks Good button.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid
              item
              lg={12}
              md={12}
              xl={12}
              xs={12}
            >
              <Card>
                <CardContent>
                  <div style={{ width: '100%', textAlign: 'right' }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid item
                            lg={12}
                            md={12}
                            xl={12}
                            xs={12}
                            container>
                        <KeyboardDatePicker
                          disableToolbar
                          variant="inline"
                          format="dd/MM/yyyy"
                          margin="normal"
                          id="date-picker-inline"
                          label="Orders entered on"
                          autoOk={true}
                          value={selectedDate}
                          disableFuture={true}
                          onChange={handleDateChange}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </div>
                  <div style={{ overflowX: 'scroll', height: 800 }} className={classes.root}>
                    <DataGrid
                      rows={rows}
                      columns={[...columns, ...skuInfo.columns]}
                      sx={{...skuInfo.classes}}
                      pageSize={100}
                      disableSelectionOnClick={true}
                      onRowClick={(GridRowParams, event) => {
                        history.push("/tasks/revieworders/" + GridRowParams.row.orgId)
                      }} />
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <StyledBackdrop open={showBackdrop} />
    </>
  );
};

export default ReviewOrders;
