import axios from 'axios';

const LIVE_SKUS = new Set([
  "xcc",
  "xca",
  "xwc",
  "xcm",
  "xcx",
  "xlm",
  "xcs",
  "xgf",
  "xgs"
]);

export function classNameForSKU(skuStr, isCell) {
  const matches = skuStr.toLowerCase().match(/^(x[a-z]{2})/);

  if (matches !== null && matches.length > 0) {
    const sku = LIVE_SKUS.has(matches[0]) ? matches[0] : "dead";
    return isCell ? `sku-cell-${sku}` : `sku-${sku}`;
  }

  return null;
}

export function getSKUs(apikey) {
  return axios.get('/skus', { params: { key: apikey } })
    .then((response) => {
      let skuInfo = response.data.results;
      skuInfo.sort((a, b) => {
        return a.sorting > b.sorting ? 1 : -1;
      });

      return skuInfo;
    });
}

export function getSKUColumns(skuInfo) {
    return skuInfo.map(
        (sku) => ({
            field: sku.code,
            headerName: sku.code,
            cellClassName: `sku-${sku.code}`,
            headerClassName: `super-app-theme--header sku-header-${sku.code}`,
            sortable: false,
            type: 'number',
            headerAlign: 'center',
            align: 'center'
        })
    );
}

export function getSKUStyleClasses(skuInfo) {
  return Object.assign(
    {},
    ...skuInfo.map(
      (sku) => ({
        [`.sku-${sku.code}`]: {
          filter: 'saturate(80%) contrast(80%) brightness(150%)',
          backgroundColor: sku.brand_colour
        },
        [`.sku-header-${sku.code}`]: {
          backgroundColor: sku.brand_colour
        }
      })
    )
  );
}
