import axios from "axios";
import { useState, useEffect } from "react"
import { useParams, Redirect, useLocation } from "react-router-dom";

const Embed = (props) => {
  const [ redirectReady, setRedirectReady ] = useState(false);
  const [ redirectOverride, setRedirectOverride ] = useState(null);
  const { apiKey } = useParams();
  const { search } = useLocation();

  const params = new URLSearchParams(search);

  useEffect(() => {
    if (apiKey && !redirectReady) {
      localStorage.setItem('apikey', apiKey);

      axios.get(`/users?key=${apiKey}`)
        .then((response) => {
          localStorage.setItem('user', response.data.results[0].email);
        })
        .catch((error) => {
          console.log(error);
          setRedirectOverride('/login');
        })
        .finally(() => {
          setRedirectReady(true);
        });
    }
  }, [apiKey]);

  const redirectTo = redirectOverride || params.get('next') || '/dashboard';

  return (
    <>
      {
        redirectReady ? <Redirect to={redirectTo} /> : <p>Please wait...</p>
      }
    </>
  )
}

export default Embed;
