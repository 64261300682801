import { createContext, useState, useEffect } from "react";
import axios from "axios";

export const CampaignInfo = createContext({
  dates: null
});

export const CampaignInfoProvider = (props) => {
  const [campaignStart, setCampaignStart] = useState(localStorage.getItem('campaignStart'));
  const [campaignEnd, setCampaignEnd] = useState(localStorage.getItem('campaignEnd'));
  
  useEffect(() => {
    const cStart = localStorage.getItem('campaignStart');
    const cEnd = localStorage.getItem('campaignEnd');

    if (!cStart || !cEnd) {
      axios.get(`/users?key=${localStorage.getItem('apikey')}`)
        .then((response) => {
          const start = response.data.results[0].campaign_reporting_first_date;
          const end = response.data.results[0].campaign_reporting_last_date;

          localStorage.setItem('campaignStart', start);
          localStorage.setItem('campaignEnd', end);

          setCampaignStart(start);
          setCampaignEnd(end);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  return (
    <CampaignInfo.Provider value={{start: campaignStart, end: campaignEnd}}>
      {props.children}
    </CampaignInfo.Provider>
  );
}
