import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Popover from '@material-ui/core/Popover';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Collapse from '@material-ui/core/Collapse';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Hidden from '@material-ui/core/Hidden';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import axios from "axios";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { SKUInfoProvider } from './SKUInfo';

import {
  Route,
  Link,
  useLocation
} from "react-router-dom";
import Badge from '@material-ui/core/Badge';
import Notifications from "@material-ui/icons/Notifications";
import Person from "@material-ui/icons/Person";
import logo from '../xc-logo.jpg';
import routes from '../routes';
import { CampaignInfoProvider } from './CampaignInfo';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  typography: {
    padding: theme.spacing(2),
  },
  list_root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  appBar: {
    zIndex: theme.zIndex.drawer - 1,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

  },
  appBarShift: {
    zIndex: theme.zIndex.drawer - 1,
    width: `100%`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    backgroundColor: 'rgb(242, 242, 242)',
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  title: {
    flexGrow: 1,
  },
  list: {
    width: 250,
    backgroundColor: '#000',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer - 2,
    color: '#fff',
  },
}));

export default function MainLayout(props) {
  const getQueryVariable = (varQuy) => {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] === varQuy) { return pair[1]; }
    }
    return (false);
  }
  if (getQueryVariable('key')) {
    localStorage.setItem("apikey", getQueryVariable('key'));
  } else if (getQueryVariable('seller_key')) {
    localStorage.setItem("apikey", getQueryVariable('seller_key'));
  }

  const classes = useStyles();
  const location = useLocation();
  const [state, setState] = React.useState({ left: false });
  const [menuSel, setMenuSel] = React.useState([]);

  const toggleDrawer = (side, open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  const menuClick = (menu)=> {
    setMenuSel((menuSel) => {
      const tmpMenuSel = [...menuSel]
      tmpMenuSel.indexOf(menu) === -1 ? tmpMenuSel.push(menu) : tmpMenuSel.splice(tmpMenuSel.indexOf(menu),1)
      return tmpMenuSel
    }) 
  }

  const sideList = side => (
    <div
      className={classes.list}
      role="presentation"
      onKeyDown={toggleDrawer(side, false)}
    >
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            <div style={{ textAlign: 'center' }}><img src={logo} style={{ width: '70%' }} alt="Xmas Cookies" /></div>
          </ListSubheader>
        }
        className={classes.list_root}>
        {routes.map((route, i) => (
          route['type'] !== 'link' && <div key={i}>{route['type']==='list' ? <ListItem button component={Link} to={route['path']} selected={location.pathname === route['path']}>
          <ListItemIcon>{route['icon']}</ListItemIcon>
          <ListItemText primary={route['title']} />
        </ListItem> : <ListItem button onClick={()=>{menuClick(route['path'])}}>
            <ListItemIcon>{route['icon']}</ListItemIcon>
            <ListItemText style={{ fontWeight: 'bold', color: '#999999' }} primary={route['title']} />
            {menuSel.indexOf(route['path']) === -1 ? <ExpandLess style={{ color: '#999999' }} /> : <ExpandMore style={{ color: '#999999' }} />}
          </ListItem>}
            <Collapse in={menuSel.indexOf(route['path']) === -1} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {
                  route['routes'].map((childRoute, j) => (
                    <ListItem button className={classes.nested} component={Link} to={childRoute['path']} key={j} selected={location.pathname === childRoute['path']}>
                      <ListItemText primary={childRoute['title']} />
                    </ListItem>
                  ))
                }
              </List>
            </Collapse>
            <Divider /></div>
        ))}
        <ListItem button onClick={handleLogOut}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary="Log out" />
        </ListItem>

        <ListSubheader style={{ textAlign: 'center', lineHeight: '200%', marginTop: '10%' }}>MyCa Core, version 1.4<br />
          © 2021 Cookie Time Limited</ListSubheader>
      </List>
    </div>
  );

  const handleLogOut = () => {
    if (window.confirm("Are you sure you want to logout?")) {
      localStorage.clear();
      window.location.href = "/login";
    }
  }

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    if (alert.length > 0 || message.length > 0) {
      setAnchorEl(event.currentTarget);
    }

  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const popOpen = Boolean(anchorEl);
  const id = popOpen ? 'simple-popover' : undefined;

  const [alert, setAlert] = React.useState([]);
  const [message, setMessage] = React.useState([]);


  const getAlert = () => {
    axios.get('/selleralerts?key=' + localStorage.getItem("apikey"))
      .then((response) => {
        setAlert(response.data.results);
      })
  }

  const getMessage = () => {
    axios.get('/sellermessages?key=' + localStorage.getItem("apikey"))
      .then((response) => {
        setMessage(response.data.results);
      })
      .catch(function (error) {
        localStorage.clear();
        window.location.href = "/login";
      });
  }

  useEffect(() => {
    getAlert();
    getMessage();
  }, []);



  return (
    <div className={classes.root}>
      <SwipeableDrawer
        open={state.left}
        onClose={toggleDrawer('left', false)}
        onOpen={toggleDrawer('left', true)}
        disableSwipeToOpen={getQueryVariable('key')}
      >
        {sideList('left')}
      </SwipeableDrawer>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: false,
        })}
        style={{ display: getQueryVariable('key') ? 'none' : 'block' }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer('left', true)}
            edge="start"
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {props.title}
          </Typography>
          <Hidden xsDown>
            <div>
              <span className={classes.title}>
                {localStorage.getItem("user")}
              </span>
              <IconButton aria-label="Notifications" aria-controls="menu-appbar" aria-haspopup="true" color="inherit" onClick={handleClick}>
                <Badge badgeContent={alert.length + message.length} color="secondary">
                  <Notifications />
                </Badge>
              </IconButton>
              <Popover
                id={id}
                open={popOpen}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <Typography className={classes.typography}>{alert.map(row => { return <div><NotificationsNoneIcon fontSize="small" /> {row.message}</div> })}<Divider style={{ margin: '12px' }} />{message.map(row => { return <div><MailOutlineIcon fontSize="small" /> {row.message}</div> })}</Typography>
              </Popover>
              <IconButton aria-label="Profile" aria-controls="menu-appbar" aria-haspopup="true" color="inherit" onClick={handleLogOut}>
                <Person />
              </IconButton>
            </div>
          </Hidden>
        </Toolbar>
      </AppBar>

      <main
        className={clsx(classes.content, {
          [classes.contentShift]: true,
        })}
      >
        {!getQueryVariable('key') && <div className={classes.drawerHeader} />}
        <div>
          <CampaignInfoProvider>
            <SKUInfoProvider>
              <Route component={props.children} />
            </SKUInfoProvider>
          </CampaignInfoProvider>
        </div>
        <Backdrop className={classes.backdrop} open={false}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </main>
    </div>
  );
}
