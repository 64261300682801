import {
  SentimentNeutral,
  SentimentVeryDissatisfied,
  SentimentVerySatisfied
} from '@mui/icons-material';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  TextField,
  Checkbox,
  Button,
  Stack,
  Typography
} from '@mui/material';

import { useState } from 'react';

const SurveyDialog = (props) => {
  const [surveyAnswers, setSurveyAnswers] = useState({
    "happiness_level": 0,
    "survey_question_1": "",
    "survey_question_2": "",
    "is_callback_requested": false,
  });

  const [failedSubmit, setFailedSubmit] = useState(false);

  const handleSubmit = () => {
    if (!surveyAnswers.survey_question_1 || !surveyAnswers.survey_question_2) {
      setFailedSubmit(true);
      return;
    }

    setFailedSubmit(false);

    if (props.onSubmit) {
      props.onSubmit(surveyAnswers);
    }
  };

  const handleCancel = () => {
    if (props.onCancel) {
      props.onCancel();
    }
  }

  const handleAnswerChange = (value, key) => {
    setSurveyAnswers((answers) => {
      const tmpAnswers = { ...answers };
      tmpAnswers[key] = value;
      return tmpAnswers;
    });
  }

  return (
    <>
      <Dialog aria-labelledby="form-dialog-title" onClose={props.onCancel} {...props}>
        <DialogTitle id="form-dialog-title">
          How was your day?
        </DialogTitle>
        <DialogContent sx={{width: 600}}>
          <Stack spacing={2}>
            <FormControl>
              <FormLabel>Satisfaction</FormLabel>
              <RadioGroup
                row
                aria-label="position"
                name="position"
                value={surveyAnswers.happiness_level}
                onChange={(event) => {
                  handleAnswerChange(event.target.value, "happiness_level");
                }}>
                <FormControlLabel
                  value={-1}
                  control={<Radio icon={<SentimentVeryDissatisfied />}
                    checkedIcon={<SentimentVeryDissatisfied />} />}
                  label="Sad" />
                <FormControlLabel
                  value={0}
                  control={<Radio icon={<SentimentNeutral />}
                    checkedIcon={<SentimentNeutral />} />}
                  label="Neutral" />
                <FormControlLabel
                  value={1}
                  control={<Radio icon={<SentimentVerySatisfied />}
                    checkedIcon={<SentimentVerySatisfied />} />}
                  label="Happy" />
              </RadioGroup>
            </FormControl>
            <TextField
              error={!surveyAnswers.survey_question_1 && failedSubmit}
              label="Any questions or concerns after today?"
              multiline
              minRows={3}
              value={surveyAnswers.survey_question_1}
              onChange={(event) => {
                handleAnswerChange(event.target.value, "survey_question_1");
              }}
            />
            <TextField
              error={!surveyAnswers.survey_question_2 && failedSubmit}
              label="What are you most proud of today?"
              multiline
              minRows={3}
              value={surveyAnswers.survey_question_2}
              onChange={(event) => {
                handleAnswerChange(event.target.value, "survey_question_2");
              }}
            />
            <FormControlLabel
              control={<Checkbox checked={surveyAnswers.is_callback_requested}
                onChange={(event) => {
                  handleAnswerChange(event.target.checked, "is_callback_requested");
                }} />}
              label="I need an HQ member to call me"
            />
            {
              failedSubmit && (
                <Typography visible={failedSubmit}>
                  Please complete the survey questions to continue.
                </Typography>
              ) || null
            }
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SurveyDialog;
