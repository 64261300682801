import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Typography, Backdrop, CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { DataGrid } from '@material-ui/data-grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import axios from "axios";
import Resizer from "react-image-file-resizer";
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { Helmet } from "react-helmet";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    'aria-controls': `nav-tabpanel-${index}`,
  };
}

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  container: {
    maxHeight: 960,
  }, backdrop: {
    zIndex: theme.zIndex.drawer - 2,
    color: '#fff',
  },
  imageList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
}));



export default function NavTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClose = () => {
    setDialOpen('');
  };

  const shipOdrsCol = [
    {
      field: 'name',
      headerName: 'Order#',
      headerClassName: 'super-app-theme--header',
      flex: 1,
      headerAlign: 'center',
    },
    {
      field: 'consignment_number',
      headerName: 'Consignment#',
      headerClassName: 'super-app-theme--header',
      flex: 1,
      headerAlign: 'center'
    },
    {
      field: 'date_dispatched',
      headerName: 'Date Shipped	',
      headerClassName: 'super-app-theme--header',
      sortable: false,
      type: 'number',
      flex: 1,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'date_received',
      headerName: 'Date Received',
      headerClassName: 'super-app-theme--header',
      sortable: false,
      type: 'number',
      flex: 1,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'status',
      headerName: 'Status',
      headerClassName: 'super-app-theme--header',
      sortable: false,
      type: 'number',
      flex: 1,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'Action',
      headerName: 'Action',
      headerClassName: 'super-app-theme--header',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: (para) => {
        return <div>{para.row.status === 'Received' && <Button variant="outlined" size="small" color="primary" onClick={() => handleClickDialOpen(para, 'claim')}>
          Claim
        </Button>} <Button variant="outlined" size="small" color="primary" onClick={() => handleClickDialOpen(para, 'view')}>
            View
          </Button></div>
      },
    }
  ];

  const [backDropSts, setBackDropSts] = React.useState(false);
  const [shipOdrs, setShipOdrs] = React.useState([]);
  const [orderDtl, setOderDtl] = React.useState({});
  const [dialOpen, setDialOpen] = React.useState('');
  const [clmItem, setClmItem] = React.useState([]);
  const [clmItemImg, setClmItemImg] = React.useState([]);
  const [clmDesc, setClmDesc] = React.useState('');
  const [signSts, setSignSts] = React.useState(false);

  const [userName, setUserName] = React.useState('')

  const handleSignCheck = (val) => {
    setSignSts(val)
  }

  const handleClaimDescChange = (val) => {
    setClmDesc(val)
  }

  const onImgChange = (event) => {
    console.log(event.target.files[0].name)
    if (event.target.files[0]) {
      try {
        Resizer.imageFileResizer(
          event.target.files[0],
          1920,
          1080,
          "JPEG",
          80,
          0,
          (uri) => {
            setClmItemImg((clmItemImg) => {
              const tmpClmItemImg = [...clmItemImg]
              tmpClmItemImg.push({ 'file_name': event.target.files[0].name, 'mime_type': 'image/jpeg', 'data': uri.replace('data:image/jpeg;base64,','') })
              return tmpClmItemImg
            });
          },
          "base64",
        );
      } catch (err) {
        console.log(err);
      }
    }
    console.log(clmItemImg)
  }

  const handleClmSubmit = (id) => {    
    if (clmDesc) {
      if (clmItemImg.length > 0) {
        if (signSts) {
          setBackDropSts(true);
          const tmpClmReq = {}
          tmpClmReq.seller_remarks = clmDesc;
          tmpClmReq.is_signed = true;
          tmpClmReq.lines = clmItem.filter(row => (row.reason !== 'total'));
          tmpClmReq.attachments = clmItemImg;

          axios.post('/stockshipments/id/' + id + '/claim?key=' + localStorage.getItem("apikey"), JSON.stringify(tmpClmReq), {
            headers: { 'Content-Type': 'application/json' }
          })
            .then((response) => {
              console.log(response)
              setBackDropSts(false);
              alert('success');
              getShipOdrs();
              handleClose();
            })
            .catch((error) => {
              setBackDropSts(false);
            })
        } else {
          alert('Before clicking Submit you must have at least 1 unit claimed, plus a description, photo and digital signature.')
        }
      } else {
        alert('Before clicking Submit you must have at least 1 unit claimed, plus a description, photo and digital signature.')
      }      
    } else {
      alert('Before clicking Submit you must have at least 1 unit claimed, plus a description, photo and digital signature.')
    }
  }

  const handleClaimChange = (clmCode, clmQuan, clmType) => {
    if (orderDtl.claims.length > 0) {
      alert('Sorry, you have already loaded a claim for this shipment. To modify this claim please contact HQ.')
    } else {
      setClmItem((clmItem) => {
        const tmpClmItem = [...clmItem];
        if (Object.keys(tmpClmItem).find(key => (tmpClmItem[key]['sku_code'] === clmCode && tmpClmItem[key]['reason'] === clmType))) {
          if ((tmpClmItem[Object.keys(tmpClmItem).find(key => (tmpClmItem[key]['sku_code'] === clmCode && tmpClmItem[key]['reason'] === 'total'))]['quantity'] * 12 - tmpClmItem.filter(row => row.sku_code === clmCode && row.reason !== 'total' && row.reason !== clmType).reduce((accumulator, current) => accumulator + current.quantity * 12, 0) - parseInt(clmQuan)) >= 0) {
            tmpClmItem[Object.keys(tmpClmItem).find(key => (tmpClmItem[key]['sku_code'] === clmCode && tmpClmItem[key]['reason'] === clmType))]['quantity'] = (parseInt(clmQuan) < 0 ? 0 : parseInt(clmQuan))
          } else {
            alert('Over total of items')
          }
        } else {
          if ((tmpClmItem[Object.keys(tmpClmItem).find(key => (tmpClmItem[key]['sku_code'] === clmCode && tmpClmItem[key]['reason'] === 'total'))]['quantity'] * 12 - tmpClmItem.filter(row => row.sku_code === clmCode && row.reason !== 'total' && row.reason !== clmType).reduce((accumulator, current) => accumulator + current.quantity * 12, 0) - parseInt(clmQuan)) >= 0) {
            tmpClmItem.push({ 'sku_code': clmCode, 'reason': clmType, 'quantity': (parseInt(clmQuan) < 0 ? 0 : parseInt(clmQuan)) });
          } else {
            alert('Over total of items')
          }    
        }
        return tmpClmItem;
      })
    }     
  }

  const handleDeleteClaimImage = (index) => {
    setClmItemImg((clmItemImg) => {
      const tmpClmItemImg = [...clmItemImg]
      tmpClmItemImg.splice(index, 1)
      return tmpClmItemImg
    });
  }

  const handleClickDialOpen = (val, type) => {
    setOderDtl(val.row);
    setClmItem((clmItem) => {
      const tmpClmItem = [...clmItem]
      val.row.items.map(row => (
        tmpClmItem.push({sku_code: row.sku_code, reason: "total", quantity: row.quantity})
      ));
      return tmpClmItem
    });
    type === 'view' ? setDialOpen('view') : setDialOpen('claim')
  };

  const getShipOdrs = () => {
    setBackDropSts(true);
    axios.get('/stockshipments?key=' + localStorage.getItem("apikey"))
      .then((response) => {
        setShipOdrs(response.data.results);
        if (Object.keys(orderDtl).length !== 0) {
          setOderDtl(response.data.results[Object.keys(response.data.results).find(key => (response.data.results[key]['id'] === orderDtl['id']))]);
        }
        setBackDropSts(false);
      })
  }
  const handleOdrReceive = (tranId) => {
    if (window.confirm("You are about to confirm receipt of this shipment. Please immediately inspect the contents of the shipment, and lodge any necessary claims using the Claim button on the Shipment History tab.")) {
      axios.put('/stockshipments/id/' + tranId + '?key=' + localStorage.getItem("apikey"), JSON.stringify({ "action": "receive" }), {
        headers: { 'Content-Type': 'application/json' }
      })
        .then((response) => {
          getShipOdrs();
          handleClose();
        })
        .catch((error) => {
        })
    }
  }

  useEffect(() => {
    getShipOdrs();
    axios.get('/users?key=' + localStorage.getItem("apikey"))
      .then((response) => {
        setUserName(response.data.results[0].first_name + ' ' + response.data.results[0].last_name)
      })
  }, []);

  return (
    <>
      <Helmet>
        <title>Shipments | Xmas Cookies</title>
      </Helmet>
      <div className={classes.root}>
        <AppBar position="static" color="default">
          <Tabs
            variant="fullWidth"
            value={value}
            onChange={handleChange}
            aria-label="nav tabs example"
            indicatorColor="primary"
            textColor="primary"
          >
            <LinkTab label="Active Shipments" href="/drafts" {...a11yProps(0)} />
            <LinkTab label="Shipment History" href="/trash" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <div style={{ height: 800, width: '100%' }}>
            <div style={{ display: 'flex', height: '100%' }}>
              <div style={{ flexGrow: 1 }} className={classes.root}>

                <DataGrid
                  rows={shipOdrs.filter(row => row.status !== 'Received')}
                  columns={shipOdrsCol}
                  pageSize={100}
                  disableSelectionOnClick={true}
                />
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div style={{ height: 800, width: '100%' }}>
            <div style={{ display: 'flex', height: '100%' }}>
              <div style={{ flexGrow: 1 }} className={classes.root}>

                <DataGrid
                  rows={shipOdrs.filter(row => row.status === 'Received')}
                  columns={shipOdrsCol}
                  pageSize={100}
                  disableSelectionOnClick={true}
                />
              </div>
            </div>
          </div>
        </TabPanel>
        <Dialog open={Boolean(dialOpen === 'claim')} onClose={handleClose} maxWidth='md' aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Claim for damage/loss in shipment {orderDtl.hasOwnProperty('id') && orderDtl.consignment_number}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              All shipments are to be checked immediately upon receipt. If a claim is to be made, this form must be completed by midday on the same day. Contact Cookie Time if you need assistance making a claim.
            </DialogContentText>
            <Typography variant="h6" gutterBottom>
              1. Specify the number of buckets damaged or missing.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
              Remember to specify all claim quantities in buckets, not cartons.
            </Typography>
            <TableContainer component={Paper} style={{ maxHeight: 400 }}>
              <Table stickyHeader className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Item</TableCell>
                    <TableCell>Damaged</TableCell>
                    <TableCell>Missing</TableCell>
                    <TableCell>Accepted</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orderDtl.hasOwnProperty('id') && orderDtl.items.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {row.sku_code}
                      </TableCell>
                      <TableCell><TextField
                        id="standard-number"
                        type="number"
                        value={Object.keys(clmItem).find(key => (clmItem[key]['sku_code'] === row.sku_code && clmItem[key]['reason'] === 'damage')) ? clmItem[Object.keys(clmItem).find(key => (clmItem[key]['sku_code'] === row.sku_code && clmItem[key]['reason'] === 'damage'))]['quantity'] : 0}
                        onChange={(eve) => { handleClaimChange(row.sku_code, eve.target.value, 'damage') }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      /></TableCell>
                      <TableCell><TextField
                        id="standard-number"
                        type="number"
                        value={Object.keys(clmItem).find(key => (clmItem[key]['sku_code'] === row.sku_code && clmItem[key]['reason'] === 'missing')) ? clmItem[Object.keys(clmItem).find(key => (clmItem[key]['sku_code'] === row.sku_code && clmItem[key]['reason'] === 'missing'))]['quantity'] : 0}
                        onChange={(eve) => { handleClaimChange(row.sku_code, eve.target.value, 'missing') }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      /></TableCell>
                      <TableCell>{((clmItem[Object.keys(clmItem).find(key => (clmItem[key]['sku_code'] === row.sku_code && clmItem[key]['reason'] === 'total'))]['quantity']) - (Object.keys(clmItem).find(key => (clmItem[key]['sku_code'] === row.sku_code && clmItem[key]['reason'] === 'damage')) ? clmItem[Object.keys(clmItem).find(key => (clmItem[key]['sku_code'] === row.sku_code && clmItem[key]['reason'] === 'damage'))]['quantity'] : 0) - (Object.keys(clmItem).find(key => (clmItem[key]['sku_code'] === row.sku_code && clmItem[key]['reason'] === 'missing')) ? clmItem[Object.keys(clmItem).find(key => (clmItem[key]['sku_code'] === row.sku_code && clmItem[key]['reason'] === 'missing'))]['quantity'] : 0) * 12)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <br />
            <Typography variant="h6" gutterBottom>
              2. Describe the exact nature of the damage/loss being claimed.      </Typography>
            <TextField
              id="outlined-multiline-static"
              multiline
              fullWidth
              rows={4}
              defaultValue={clmDesc}
              onChange={(eve) => { handleClaimDescChange(eve.target.value) }}
              variant="outlined"
            />
            <br /><br />
            <Typography variant="h6" gutterBottom>
              3. Upload at least one jpg photo clearly showing the problem.
            </Typography>
            <ImageList className={classes.imageList} cols={6}>
              {clmItemImg.map((item, index) => (
                <ImageListItem key={index}>
                  <img src={'data:image/jpeg;base64,' + item.data} alt={''} />
                  <ImageListItemBar
                    actionIcon={
                      <IconButton>
                        <DeleteIcon className={classes.title} style={{ color: 'white' }} onClick={() => { handleDeleteClaimImage(index) }} />
                      </IconButton>
                    }
                  />
                </ImageListItem>
              ))}
            </ImageList>
            <Typography variant="caption" display="block" gutterBottom>
              Hint: To upload multiple photos, please click upload again.
            </Typography>
            <input
              accept="image/*"
              className={classes.input}
              id="contained-button-file"
              type="file"
              onClick={(event) => {
                event.target.value = null
              }}

              onChange={onImgChange}
              style={{ display: 'none' }}
            />
            <label htmlFor="contained-button-file">
              <Button variant="contained" color="primary" component="span">
                Upload
              </Button>
            </label>
            <br /><br />
            <Typography variant="h6" gutterBottom>
              4. Digitally sign and submit this claim.</Typography>
            <FormControlLabel
              control=
              {<Checkbox name="antoine"
                checked={signSts}
                onChange={(eve) => { handleSignCheck(eve.target.checked) }}

              />}
              label={"I, " + userName + " , hereby affirm that the details provided in this claim are true and correct."}
            />
            <Typography variant="caption" display="block" gutterBottom>
              N.B.: Once submitted you cannot alter this claim without contacting Cookie Time.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={() => { handleClmSubmit(orderDtl.id) }} color="primary">
              Submit Claim
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={Boolean(dialOpen === 'view')} onClose={handleClose} maxWidth='md' aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Items in consignment {orderDtl.hasOwnProperty('id') && orderDtl.consignment_number}</DialogTitle>
          <DialogContent>
            <TableContainer component={Paper} style={{ maxHeight: 400 }}>
              <Table stickyHeader className={classes.table} aria-label="simple table">
                <TableBody>
                  {orderDtl.hasOwnProperty('id') && orderDtl.items.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{row.sku_code}</TableCell>
                      <TableCell>{row.quantity}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => { handleOdrReceive(orderDtl.id) }} color="primary" variant="contained">
              Receive
            </Button>
            <Button onClick={handleClose} color="primary" variant="outlined">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Backdrop className={classes.backdrop} open={backDropSts}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    </>
  );
}
