import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core';
import theme from './theme/index';
import routes from './routes';
import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_DOMAIN;

axios.interceptors.request.use((config) => {
  config.headers['CT-DeviceID'] = 'myca-web';
  config.headers['Content-Type'] = 'application/json';
  return config;
})

const extendRountes = (routesArray) => {
  var tmpRouteArray = [];
  routesArray.map(x => (x['routes'].length) > 0 ? tmpRouteArray.push(x, ...(x['routes'])) : tmpRouteArray.push(x));
  return tmpRouteArray;
}

export default function App() {
  const routeList = extendRountes(routes);
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Switch>
          {routeList.map((route, i) => (
            <Route key={i} exact path={route.path}>
              {route.component}
            </Route>
          ))}
        </Switch>
      </ThemeProvider>
    </Router>
  );
}

