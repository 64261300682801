import { Table, TableHead, TableBody, TableRow, TableCell, TextField, Paper } from "@mui/material";
import Section from "../Section"
import StocktakeDialog from "../StocktakeDialog";
import { useState, useContext, useEffect } from "react";

import { SKUInfo } from "../../SKUInfo";
import { SKUListStyle } from "../SKUList";

const StockCheck = (props) => {
  const [showDialog, setShowDialog] = useState(false);
  const skuInfo = useContext(SKUInfo);
  const [stock, setStock] = useState([]);
  
  useEffect(() => {
    setStock(() => {
      if (props.stockFigures.length > 0) {
        return props.stockFigures.map((actual) => {
          const actualQuantity = actual.quantity;
          const expectedQuantity = props.expectedFigures.find((row) => row.sku_code === actual.sku_code)?.quantity || 0;

          const actualCartons = Math.floor(actualQuantity / 12);
          const expectedCartons = Math.floor(expectedQuantity / 12);
          const actualUnits = actualQuantity % 12;
          const expectedUnits = expectedQuantity % 12;

          return {
            'ok': actualQuantity === expectedQuantity,
            'sku_code': actual.sku_code,
            'actualCartons': actualCartons,
            'actualUnits': actualUnits,
            'expectedCartons': expectedCartons,
            'expectedUnits': expectedUnits
          };
        });
      } else {
        return skuInfo.info.map((info) => ({
          'ok': true,
          'sku_code': info.code,
          'actualCartons': 0,
          'actualUnits': 0,
          'expectedCartons': 0,
          'expectedUnits': 0
        }));
      }
    });
  }, [props.stockFigures, skuInfo]);

  const allOk = stock.findIndex((status) => { return !status.ok; }) === -1;

  const handleCartonsChange = (sku, cartons) => {
    const key = Object.keys(props.stockFigures).find((key) =>
      props.stockFigures[key].sku_code === sku
    );

    const quantity = props.stockFigures[key]?.quantity || 0;
    const buckets = quantity % 12;

    handleStockChange(sku, cartons || 0, buckets || 0);
  };

  const handleUnitsChange = (sku, units) => {
    const key = Object.keys(props.stockFigures).find((key) =>
      props.stockFigures[key].sku_code === sku
    );

    const quantity = props.stockFigures[key]?.quantity || 0;
    const cartons = Math.floor(quantity / 12);

    handleStockChange(sku, cartons || 0, units || 0);
  };

  const handleStockChange = (sku, cartons, units) => {
    const quantity = cartons * 12 + units;

    if (props.onQuantityChange) {
      props.onQuantityChange(sku, quantity);
    }
  }

  const handleConfirm = () => {
    setShowDialog(true);
  }

  const handleDialogClose = (note) => {
    setShowDialog(false);
    props.onConfirm(note);
  }

  return (
    <>
      <Section
        title="Daily Stock Check"
        {...props}
        onConfirm={handleConfirm}
      >
        <Paper variant="outlined">
          <Table size="small" sx={SKUListStyle}>
            <TableHead>
              <TableRow>
                <TableCell>SKU</TableCell>
                <TableCell>Whole Cartons</TableCell>
                <TableCell>Single Buckets</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                stock.map((row, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>
                        {row.sku_code}
                      </TableCell>
                      <TableCell>
                        <TextField
                          disabled={props.isComplete}
                          value={row.actualCartons}
                          onChange={(event) => {
                            handleCartonsChange(row.sku_code, parseInt(event.target.value || 0));
                          }}
                          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                          size="small"
                          variant="standard"
                        />
                      </TableCell>

                      <TableCell>
                        <TextField
                          disabled={props.isComplete}
                          value={row.actualUnits}
                          onChange={(event) => {
                            handleUnitsChange(row.sku_code, parseInt(event.target.value));
                          }}
                          inputProps={{ inputMode: 'numeric', pattern: '[0-9]+' }}
                          size="small"
                          variant="standard"
                        />
                      </TableCell>
                    </TableRow>
                  );
                })
              }
            </TableBody>
          </Table>
        </Paper>
      </Section>
      <StocktakeDialog
        open={showDialog}
        onClose={() => setShowDialog(false)}
        onConfirm={handleDialogClose}
        error={!allOk}
        stock={stock}
      />
    </>
  );
};

export default StockCheck;
