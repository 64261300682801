import React, { useEffect } from 'react';
import {
    Box,
    Container,
    Grid,
    Button,
    FormControl,
    Link,
    InputLabel,
    MenuItem,
    FormHelperText,
    Select, Backdrop, CircularProgress, LinearProgress
} from '@material-ui/core';
import clsx from 'clsx';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import axios from "axios";

import { Helmet } from "react-helmet";
import {
    useParams,
} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';


const orgColumns = [
    { id: 'name', label: '1. ORGANISATIONS', minWidth: 170 }
];
const cusColumns = [
    { id: 'name', label: '2. CUSTOMERS', minWidth: 170 }
];

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 960,
    }, backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

const useFormStyles = makeStyles((theme) => ({
    root: {
        padding: '2px 4px',
        margin: '6px 4px',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'right',
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
}));

const useOrderStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        margin: theme.spacing(1),
    },
    withoutLabel: {
        marginTop: theme.spacing(3),
    },
    textField: {
        width: '25ch',
    },
}));


const OrderDetail = (props) => {
    let { id } = useParams();
    const classes = useStyles();
    const formClasses = useFormStyles();
    const orderClasses = useOrderStyles();
    const [open, setOpen] = React.useState(false);
    const [digCusOpen, setDigCusOpen] = React.useState(false);
    const [selectedDate, setSelectedDate] = React.useState(new Date());

    const [orgRows, setOrgRows] = React.useState([]);

    const [cusRows, setCusRows] = React.useState([]);
    const [orgRowId, setOrgRowId] = React.useState(id);
    const [cusRowId, setCusRowId] = React.useState(id);

    const [cusOdrRows, setCusOdrRows] = React.useState([]);

    const [orgDtl, setOrgDtl] = React.useState([]);
    const [cusList, setCusList] = React.useState('');
    const [cusSltName, setCusSltName] = React.useState('');

    const [backDropSts, setBackDropSts] = React.useState(false);
    const [loadCust, setLoadCust] = React.useState(false);
    const [loadCustOdr, setLoadCustOdr] = React.useState(false);

    const [searchContent, setSearchContent] = React.useState('');

    const handleSearchClick = () => {

    }

    const handleSearchContentChange = (val) => {
        setSearchContent(val);
    }

    const handleCusList = (event) => {
        setCusList(event.target.value);
    }

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };
    const handleClickOpen = (orgId, orgName, orgAdd) => {
        setOpen(true);
        setOrgDtl({ id: orgId, name: orgName, add: orgAdd })
    };
    const handleDigCusOpen = () => {
        setDigCusOpen(true);
    }
    const handleDigCusClose = () => {
        setDigCusOpen(false);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleOrgRowClick = (rowId) => {
        setOrgRowId(rowId);
        getCusList(rowId);
        setCusList('');
    }

    const handleCusRowClick = (rowId, rowName) => {
        setCusRowId(rowId);
        getCusOrder(rowId);
        setCusSltName(rowName)
    }

    const getOrgList = (orgId) => {
        setBackDropSts(true);
        axios.get('/organisations?key=' + localStorage.getItem("apikey"))
            .then((response) => {
                setBackDropSts(false);
                setOrgRows(response.data.results);
                let anchorElement = document.getElementById(orgId);
                if (anchorElement) { anchorElement.scrollIntoView({ block: "center" }); }
            })
    }

    const getCusOrder = (cusId) => {
        setCusOdrRows([]);
        setLoadCustOdr(true);
        axios.get('/orders/customer_id/' + cusId + '?key=' + localStorage.getItem("apikey"))
            .then((response) => {
                setLoadCustOdr(false);
                setCusOdrRows(response.data.results);
                console.log(response.data.results)
            })
    }

    const getCusList = (orgId) => {
        setCusRows([]);
        setLoadCust(true);
        axios.get('/customers/organisation_id/' + orgId + '?key=' + localStorage.getItem("apikey"))
            .then((response) => {
                setLoadCust(false);
                setCusRows(response.data.results)
            })
    }

    useEffect(() => {
        getOrgList(id);
        getCusList(id);
    }, []);

    return (
        <>
            <Helmet>
                <title>Review Orders | Xmas Cookies</title>
            </Helmet>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    py: 3
                }}
            >
                <Container maxWidth={false}>
                    <Grid
                        container
                        spacing={3}
                    >
                        <Grid
                            item
                            lg={3}
                            sm={3}
                            xl={3}
                            xs={12}
                        >
                            <Paper className={classes.root}>
                                <Paper component="form" className={formClasses.root}>
                                    <InputBase
                                        className={formClasses.input}
                                        placeholder="SEARCH ORGANISATIONS"
                                        inputProps={{ 'aria-label': 'SEARCH ORGANISATIONS' }}
                                        value={searchContent}
                                        onChange={(event)=>{
                                            handleSearchContentChange(event.target.value);
                                        }}
                                    />
                                    <IconButton type="button" className={formClasses.iconButton} aria-label="search" onClick={handleSearchClick}>
                                        <SearchIcon />
                                    </IconButton>
                                </Paper>
                                <TableContainer className={classes.container}>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                {orgColumns.map((column) => (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        style={{ minWidth: column.minWidth }}
                                                    >
                                                        {column.label}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {orgRows.map((row) => {
                                                return (
                                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id} selected={row.id === orgRowId} onClick={() => handleOrgRowClick(row.id)} id={row.id}>
                                                        {orgColumns.map((column) => {
                                                            const value = row;
                                                            return (
                                                                <TableCell key={column.id} align={column.align}>
                                                                    <Link
                                                                        component="button"
                                                                        variant="body2"
                                                                        // onClick={() => handleClickOpen(row.id, row.name, row.address)}
                                                                    >
                                                                        {value.name}
                                                                    </Link>
                                                                </TableCell>
                                                            );
                                                        })}
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </Grid>
                        <Grid
                            item
                            lg={3}
                            sm={3}
                            xl={3}
                            xs={12}
                        ><Paper className={classes.root}>
                                <TableContainer className={classes.container}>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                {cusColumns.map((column) => (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        style={{ minWidth: column.minWidth }}
                                                    >
                                                        {column.label}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        {loadCust && <LinearProgress />}
                                        <TableBody>
                                            {cusRows.map((row) => {
                                                return (
                                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id} selected={row.id === cusRowId} onClick={() => handleCusRowClick(row.id, row.name)} id={row.id}>
                                                        {cusColumns.map((column) => {
                                                            const value = row;
                                                            return (
                                                                <TableCell key={column.id} align={column.align}>
                                                                    {value.name}
                                                                </TableCell>
                                                            );
                                                        })}
                                                    </TableRow>
                                                );
                                            })}
                                            {/* <TableRow>
                                                <TableCell>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        className={classes.button}
                                                        onClick={handleDigCusOpen}
                                                    >
                                                        Add a customer to {orgDtl.name}
                                                    </Button>
                                                </TableCell>

                                            </TableRow> */}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>

                        </Grid><Grid
                            item
                            lg={6}
                            sm={6}
                            xl={6}
                            xs={12}
                        ><Paper className={classes.root}>
                                <TableContainer className={classes.container}>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell
                                                    align='left'
                                                >
                                                    3. ORDERS for {cusSltName}
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {loadCustOdr && <LinearProgress />}
                                        <TableBody>
                                            { loadCustOdr === false && cusOdrRows.length === 0 && <TableRow><TableCell>No Data</TableCell></TableRow>}
                                            {cusOdrRows.map((row) => {
                                                return (
                                                    <TableRow hover role="checkbox" tabIndex={-1} >
                                                        <TableCell>
                                                            <form className={classes.root} noValidate autoComplete="off">
                                                                {row.lines.map((line) => {
                                                                    return <TextField
                                                                        style={{ maxWidth: '80px' }}
                                                                        type="text"
                                                                        disabled={true}
                                                                        id="standard-start-adornment"
                                                                        className={clsx(orderClasses.margin, orderClasses.textField)}
                                                                        defaultValue={line.quantity}
                                                                        InputProps={{
                                                                            startAdornment: <InputAdornment position="start">{line.sku_code}</InputAdornment>,
                                                                        }}
                                                                    />
                                                                })}
                                                                {/* <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    size="small"
                                                                    style={{ margin: 'auto' }}
                                                                    className={classes.button}
                                                                    startIcon={<SaveIcon />}
                                                                >
                                                                    Save
                                                                </Button> */}

                                                            </form>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>


                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </Box>


            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{orgDtl.name}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {orgDtl.add}
                    </DialogContentText>
                    <FormControl className={classes.formControl}>
                        <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                            Contact
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-placeholder-label-label"
                            id="demo-simple-select-placeholder-label"
                            displayEmpty
                            className={classes.selectEmpty}
                            value={cusList}
                            onChange={handleCusList}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {cusRows.map((row) => {
                                return <MenuItem key={row.id} value={row.id}>{row.name}</MenuItem>
                            })}

                        </Select>
                        <FormHelperText>{(cusRows.find(cus => cus.id === cusList)) ? (cusRows.find(cus => cus.id === cusList)).email + ' ' + (cusRows.find(cus => cus.id === cusList)).phone : ''}</FormHelperText>
                    </FormControl>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid item
                            lg={12}
                            md={12}
                            xl={12}
                            xs={12}
                            container>
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="dd/MM/yyyy"
                                margin="normal"
                                id="date-picker-inline"
                                label="Delievery"
                                value={selectedDate}
                                onChange={handleDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </Grid>
                    </MuiPickersUtilsProvider>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleClose} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={digCusOpen} onClose={handleDigCusClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Create Customer for {orgDtl.name}</DialogTitle>
                <DialogContent>

                    <FormControl className={classes.formControl}>
                        <TextField id="standard-basic" label="Full Name" />
                        <TextField id="standard-basic" label="Email" />
                        <TextField id="standard-basic" label="Phone" />
                    </FormControl>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid item
                            lg={12}
                            md={12}
                            xl={12}
                            xs={12}
                            container>
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="dd/MM/yyyy"
                                margin="normal"
                                id="date-picker-inline"
                                label="Delievery"
                                value={selectedDate}
                                onChange={handleDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </Grid>
                    </MuiPickersUtilsProvider>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleClose} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
            <Backdrop className={classes.backdrop} open={backDropSts}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
}

export default OrderDetail
